import Link from 'next/link'
import dynamic from 'next/dynamic'
import { Fragment, useState } from 'react'
import { P4, P3, Container, Icon, H4, Input, MainButton, P2 } from '@moneymade/moneymade-ui'
import PropTypes from 'prop-types'

import useTracker from 'hooks/useTracker'
import { subscribeNewsLetterCall } from 'api/calls'
import { ROUTES } from 'static/menusConstants'
import MENU_FOOTER from 'components/footer/footerContants'

import styles from './Footer.module.scss'

const AdvertiserModal = dynamic(() => import('components/footer/Footer/AdvertiserModal/AdvertiserModal'))

const Footer = ({
  isAdvertiserModal,
  isLogged,
  discover,
  articles,
  collection,
  trending,
  reviews,
  handleSetFooterField,
  landing
}) => {
  const { handleUseTracker } = useTracker()
  const [emailInput, setEmailInput] = useState('')
  const [formStatus, setFormStatus] = useState('')
  const [isFormDisabled, setFormDisabled] = useState(false)

  // const handleOpenModal = event => {
  //   // Prevent scroll to top after link clicked
  //   event.preventDefault()
  //   handleSetFooterField({ key: 'isAdvertiserModal', value: true })
  // }

  const handleSubscribe = async () => {
    // Disable form to prevent email changes
    setFormDisabled(true)

    // Send events
    handleUseTracker('User', 'Newsletter', 'Newsletter')

    // Send email to the API
    const { success, message } = await subscribeNewsLetterCall({ email: emailInput, sendEmail: true })()

    // Processing statuses
    if (success) {
      setFormStatus('You were successfully subscribed. Thank you!')
      // Add email to Heap
    } else if (message === 'User alredy subscribed!') setFormStatus('Already subscribed.')
    else {
      setFormStatus('Something went wrong. Please try again.')
      setFormDisabled(false)
    }
  }

  return (
    <>
      <AdvertiserModal
        isOpen={isAdvertiserModal}
        handleCloseModal={() => handleSetFooterField({ key: 'isAdvertiserModal', value: false })}
      />

      <footer className={styles.Footer}>
        <Container className={styles.Container}>
          {!isLogged && !landing && (
            <div className={styles.Form}>
              <H4 type="heading" weight="light" className={styles.Title}>
                Do more with your money. Investment ideas, direct to your inbox.
              </H4>

              <div className={styles.InputBlock}>
                <Input
                  value={emailInput}
                  onChange={({ target: { value } }) => setEmailInput(value)}
                  placeholder="Email"
                  msg={formStatus}
                  disabled={isFormDisabled}
                />
                <MainButton layout="outlined" color="white" onClick={handleSubscribe} disabled={isFormDisabled}>
                  Subscribe
                </MainButton>
              </div>
            </div>
          )}

          {!landing ? (
            <div className={styles.Links}>
              <div className={styles.Social}>
                <a href="https://www.instagram.com/moneymade.io" target="_blank" rel="noreferrer">
                  <Icon icon="icon-instagram" size={22} />
                </a>
                <a href="https://www.facebook.com/MoneyMade.io" target="_blank" rel="noreferrer">
                  <Icon icon="icon-facebook" size={22} />
                </a>
                <a href="https://twitter.com/MoneyMade_io" target="_blank" rel="noreferrer">
                  <Icon icon="icon-twitter" size={22} />
                </a>
                <a href="https://www.linkedin.com/company/money-made" target="_blank" rel="noreferrer">
                  <Icon icon="icon-linkedIn" size={22} />
                </a>
              </div>

              <div className={styles.MenuList}>
                {MENU_FOOTER.map(({ title, menu }) => (
                  <div key={title} className={styles.Menu}>
                    <P2 weight="semibold" className={styles.Title}>
                      {title}
                    </P2>
                    {menu.map(({ id, label, externalLink, link }) => (
                      <Fragment key={id}>
                        {externalLink ? (
                          <a href={externalLink} target="_blank" rel="noreferrer">
                            <P3 weight="light">{label}</P3>
                          </a>
                        ) : (
                          <Link href={link}>
                            <a rel="noreferrer">
                              <P3 weight="light">{label}</P3>
                            </a>
                          </Link>
                        )}
                      </Fragment>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          ) : null}

          {!landing ? (
            <div className={styles.CustomLinks}>
              {discover ? (
                <div className={styles.List}>
                  <P2 weight="semibold">Discover</P2>
                  {discover?.map(({ name, slug }) => (
                    <Link key={slug} href={`/${ROUTES.discover}/${slug}`}>
                      <a rel="noreferrer">
                        <P3 weight="light">{name}</P3>
                      </a>
                    </Link>
                  ))}
                </div>
              ) : null}

              {trending ? (
                <div className={styles.List}>
                  <P2 weight="semibold">Trending</P2>
                  {trending?.map(({ name, slug }) => (
                    <Link key={slug} href={`/${ROUTES.assetPage}/${slug}`}>
                      <a rel="noreferrer">
                        <P3 weight="light">{name}</P3>
                      </a>
                    </Link>
                  ))}
                </div>
              ) : null}

              {articles ? (
                <div className={styles.List}>
                  <P2 weight="semibold">Articles</P2>
                  {articles?.map(({ title, slug }) => (
                    <Link key={slug} href={`/${ROUTES.learnArticle}/${slug}`}>
                      <a rel="noreferrer">
                        <P3 weight="light">{title?.length > 30 ? `${title.slice(0, 27)}...` : title}</P3>
                      </a>
                    </Link>
                  ))}
                </div>
              ) : null}

              {reviews ? (
                <div className={styles.List}>
                  <P2 weight="semibold">Reviews</P2>
                  {reviews?.map(({ name, slug }) => (
                    <Link key={slug} href={`/${ROUTES.learnReview}/${slug}`}>
                      <a rel="noreferrer">
                        <P3 weight="light">{name}</P3>
                      </a>
                    </Link>
                  ))}
                </div>
              ) : null}

              {collection ? (
                <div className={styles.List}>
                  <P2 weight="semibold">Collections</P2>
                  {collection?.map(({ title, slug }) => (
                    <Link key={slug} href={`/${ROUTES.collections}/${slug}`}>
                      <a rel="noreferrer">
                        <P3 weight="light">{title?.length > 30 ? `${title.slice(0, 27)}...` : title}</P3>
                      </a>
                    </Link>
                  ))}
                </div>
              ) : null}
            </div>
          ) : null}

          <div className={`${styles.Text} ${landing ? styles.Landing : ''}`}>
            <P4 weight="light">
              MoneyMade is not a registered broker-dealer or investment adviser. The investments identified on the
              MoneyMade website may not be purchased through MoneyMade; rather, all transactions will be directly
              between you and the third-party platform hosting the applicable investment. The information contained
              herein regarding available investments is obtained from third party sources. While MoneyMade generally
              considers such sources to be reliable, MoneyMade does not represent that such information is accurate or
              complete, and MoneyMade has not undertaken any independent review of such information.
            </P4>
            <P4 weight="light">
              The Content is for informational purposes only, you should not construe any such information or other
              material as legal, tax, investment, financial, or other advice. Nothing contained on our Site constitutes
              a solicitation, recommendation, endorsement, or offer by MoneyMade or any third party service provider to
              buy or sell any securities or other financial instruments in this or in in any other jurisdiction in which
              such solicitation or offer would be unlawful under the securities laws of such jurisdiction. Any
              references to past performance, regarding financial markets or otherwise, do not indicate or guarantee
              future results. Forward-looking statements, including without limitations investment outcomes and
              projections, are hypothetical and educational in nature. The results of any hypothetical projections can
              and may differ from actual investment results had the strategies been deployed in actual securities
              accounts.
            </P4>
            <P4 weight="light">
              All Content on this site is information of a general nature and does not address the circumstances of any
              particular individual or entity. Nothing in the Site constitutes professional and/or financial advice, nor
              does any information on the Site constitute a comprehensive or complete statement of the matters discussed
              or the law relating thereto. MoneyMade is not a fiduciary by virtue of any person’s use of or access to
              the Site or Content. You alone assume the sole responsibility of evaluating the merits and risks
              associated with the use of any information or other Content on the Site before making any decisions based
              on such information or other Content.
            </P4>
            <P4 weight="light">
              The standardized performance presented herein has been calculated by MoneyMade based on data obtained from
              the third-party platform hosting the investment and is subject to change. No representation or warranty is
              made as to the reasonableness of the methodology used to calculate such performance. Changes in the
              methodology used may have a material impact on the returns presented. Past performance is no guarantee of
              future results. In exchange for using the Site, you agree not to hold MoneyMade, its affiliates or any
              third party service provider liable for any possible claim for damages arising from any decision you make
              based on information or other Content made available to you through the Site.
            </P4>
          </div>
        </Container>
      </footer>
    </>
  )
}

Footer.propTypes = {
  isLogged: PropTypes.bool,
  isAdvertiserModal: PropTypes.bool,
  landing: PropTypes.bool,
  discover: PropTypes.array,
  articles: PropTypes.array,
  collection: PropTypes.array,
  trending: PropTypes.array,
  reviews: PropTypes.array,
  handleSetFooterField: PropTypes.func
}

Footer.defaultProps = {
  isLogged: false,
  landing: false,
  isAdvertiserModal: false,
  discover: [],
  articles: [],
  collection: [],
  trending: [],
  reviews: [],
  handleSetFooterField: () => {}
}

export default Footer
