import { HYDRATE } from 'next-redux-wrapper'

import { SET_SERVICE_FIELD, SET_SERVICE_FIELDS, SET_SERVICE_NEWS_PAGE, SET_SERVICE_NEWS_ITEMS } from './types'

const initialState = {
  investments: [],
  deals: {},
  news: {
    page: 2,
    newsData: {
      count: 0,
      items: []
    }
  },
  readHeader: [],
  pratformsHeader: []
}

const servicesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case HYDRATE: {
      const notEmptyValues = { ...payload.services }
      // Remove elements with 0 length (works with strings, arrays or objects)
      Object.keys(payload.services).forEach(service => {
        if (service === 'news') {
          if (state?.news?.newsData?.items?.length > notEmptyValues[service]?.newsData?.items?.length)
            delete notEmptyValues[service]
        } else if (!notEmptyValues[service] || !Object.keys(notEmptyValues[service]).length)
          delete notEmptyValues[service]
      })

      return { ...state, ...notEmptyValues }
    }

    case SET_SERVICE_FIELD:
      return { ...state, [payload.key]: payload.value }

    case SET_SERVICE_FIELDS:
      return payload.reduce((acc, { key, value }) => ({ ...acc, [key]: value }), { ...state })

    case SET_SERVICE_NEWS_PAGE:
      return { ...state, news: { ...state.news, page: payload } }

    case SET_SERVICE_NEWS_ITEMS:
      return { ...state, news: { ...state.news, newsData: payload } }

    default:
      return state
  }
}

export default servicesReducer
