const PROD_DOMAIN = 'moneymade.io'
const PREPROD_DOMAIN = 'moneymade-preprod.vercel.app'
const STAGE_DOMAIN = 'moneymade-stage.vercel.app'

const DEV_API_MODE = false
const REDUX_DEV_TOOLS = +process.env.NEXT_PUBLIC_REDUX_DEV_TOOLS

const SENTRY_DNS = process.env.NEXT_PUBLIC_SENTRY_DNS

const UPTURN_URL = process.env.NEXT_PUBLIC_UPTURN_URL

const REPUBLIC_URL = process.env.NEXT_PUBLIC_REPUBLIC_URL

const SUBSCRIBE_URL = process.env.NEXT_PUBLIC_SUBSCRIBE_URL

// Hotjar data
const HOTJAR_ID = +process.env.NEXT_PUBLIC_HOTJAR_ID
const HOTJAR_SV = +process.env.NEXT_PUBLIC_HOTJAR_SV

// Domain
const PRODUCTION_DOMAIN = process.env.NEXT_PUBLIC_PRODUCTION_DOMAIN

const FIREBASE_CONFIG = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID
}

export {
  PROD_DOMAIN,
  PREPROD_DOMAIN,
  STAGE_DOMAIN,
  DEV_API_MODE,
  REDUX_DEV_TOOLS,
  SENTRY_DNS,
  UPTURN_URL,
  REPUBLIC_URL,
  SUBSCRIBE_URL,
  HOTJAR_ID,
  HOTJAR_SV,
  PRODUCTION_DOMAIN,
  FIREBASE_CONFIG
}
