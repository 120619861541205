import PropTypes from 'prop-types'

import HeaderAuth from 'components/header/Header/HeaderAuth/HeaderAuthContainer'
import styles from './MobHeader.module.scss'

const MobHeader = ({ transparent, handleSetUserFields }) => {
  const handleOpenModal = (type = 'signUp') =>
    handleSetUserFields([
      { key: 'isAuthModal', value: true },
      { key: 'authModalType', value: type }
    ])

  return (
    <header className={`${styles.MobHeader}`}>
      <HeaderAuth textColor={transparent ? 'light' : 'dark'} handleOpenModal={handleOpenModal} />
    </header>
  )
}

MobHeader.propTypes = {
  transparent: PropTypes.bool,
  handleSetUserFields: PropTypes.func
}

MobHeader.defaultProps = {
  transparent: false,
  handleSetUserFields: () => {}
}

export default MobHeader
