// default date format for portfolio dashboard
const DATE_FORMAT_TOTAL_DEFAULT = '1W'
const DATE_FORMAT_PORTFOLIO_DEFAULT = '1W'

// Date formats for recharts portfolio dashboard
const PORTFOLIO_DATE_FORMATS = {
  '1W': 'MMM DD, YYYY',
  '1M': 'MMM DD, YYYY',
  '3M': 'MMM DD, YYYY',
  // '6M': 'MMM DD, YYYY',
  '1Y': 'MMM DD, YYYY',
  ALL: 'MMM DD, YYYY'
}

const ASSETS_ALLOCATION_COLORS = [
  '#23CDC3',
  '#4FBFB9',
  '#14A39A',
  '#00837B',
  '#F2F2F4',
  '#E8E8EB',
  '#C8C8C8',
  '#b8b8b8',
  '#a8a8a8',
  '#949292',
  '#807d7d'
]

const DISPLAY_FILTER = ['Total Investment', 'Total Growth']

const AUTO_POPULATED_TWEETS = [
  'I want to see my portfolio on @MoneyMade_io. It’ll make managing my investments so much easier. https://gph.is/g/a9y90Ba',
  'You and @MoneyMade_io need to play. Having my investments automatically update here is a game-changer https://gph.is/g/a9y90Ba',
  'the folk at @MoneyMade_io will make managing my investments with you so much easier. You need to get this rollin’ https://gph.is/2r2l6RI'
]

const ACCOUNT_TYPES = {
  INVESTMENT: 'INVESTMENT',
  CASH: 'CASH',
  OTHER: 'OTHER'
}

const MANUAL_ACCOUNT_INIT = {
  type: '',
  data: {}
}

const MONEYMADE_MANUAL_ACCOUNT = 'Moneymade Manual Account'

export {
  DATE_FORMAT_TOTAL_DEFAULT,
  DATE_FORMAT_PORTFOLIO_DEFAULT,
  PORTFOLIO_DATE_FORMATS,
  ASSETS_ALLOCATION_COLORS,
  DISPLAY_FILTER,
  AUTO_POPULATED_TWEETS,
  ACCOUNT_TYPES,
  MANUAL_ACCOUNT_INIT,
  MONEYMADE_MANUAL_ACCOUNT
}
