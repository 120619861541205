import { call } from 'api/callConstructor'
import {
  API_INDUSTRIES,
  API_CONNECT_DOT_APP_ENGINE_URL,
  API_CONNECT_MONEYMADE,
  API_CLOUDFUNCTIONS,
  API_WATCHLIST
} from 'api/apiConstants'

export const getAssetsCustomGetDataCall = data => {
  const url = `${API_INDUSTRIES}/custom/get-data`
  return call({
    method: 'post',
    url,
    data
  })
}

export const getUserInfoCall = token => {
  const url = `${API_CLOUDFUNCTIONS}/getUserInfo`
  return call({
    method: 'post',
    url,
    headers: { Authorization: `Bearer ${token}` }
  })
}

export const getUserProfileCall = data => {
  const url = `${API_CLOUDFUNCTIONS}/getUserInfoById`
  return call({ method: 'post', url, data })
}

export const signUpCall = data => {
  const url = `${API_CLOUDFUNCTIONS}/signUp`
  return call({ method: 'post', url, data })
}

export const editUserInfoCall = (data, token) => {
  const url = `${API_CLOUDFUNCTIONS}/editUserInfo`
  return call({
    method: 'post',
    url,
    headers: { Authorization: `Bearer ${token}` },
    data
  })
}

export const mapUserIdsCall = (data, token) => {
  const url = `${API_CLOUDFUNCTIONS}/mapUserIds`
  return call({
    method: 'post',
    url,
    headers: { Authorization: `Bearer ${token}` },
    data
  })
}

export const editUserKeysCall = (data, token) => {
  const url = `${API_CLOUDFUNCTIONS}/editUserKeys`
  return call({
    method: 'post',
    url,
    headers: { Authorization: `Bearer ${token}` },
    data
  })
}

export const getTrendingInfoCall = () => {
  const url = `${API_CLOUDFUNCTIONS}/getTrendingInfo`
  return call({ method: 'post', url })
}

export const getReviewsCall = (data, urlError) => {
  const url = `${API_CLOUDFUNCTIONS}/getReviews`
  return call({ method: 'post', url, data }, urlError)
}

export const sendQuizEmailCall = (data, signal) => {
  const url = `${API_CLOUDFUNCTIONS}/widgetQuizEmails`
  return call({ method: 'post', url, data, signal })
}

export const addReviewCall = data => {
  const url = `${API_CLOUDFUNCTIONS}/addReview`
  return call({ method: 'post', url, data })
}

export const bumpReviewCall = data => {
  const url = `${API_CLOUDFUNCTIONS}/bumpReview`
  return call({ method: 'post', url, data })
}

export const subscribeNewsLetterCall = data => {
  const url = `${API_CLOUDFUNCTIONS}/subscribeNewsletters`
  return call({ method: 'post', url, data })
}

export const subscribeUserCall = data => {
  const url = `${API_CLOUDFUNCTIONS}/subscribeNewsletters`
  return call({ method: 'post', url, data })
}

export const uploadImageCall = (data, token) => {
  const url = `${API_CLOUDFUNCTIONS}/uploadImage`
  return call({
    method: 'post',
    url,
    headers: { Authorization: `Bearer ${token}` },
    data
  })
}

export const getAssetRankCall = slug => {
  const url = `${API_CLOUDFUNCTIONS}/getIndustryRank`
  return call({ method: 'post', url, data: { slug } })
}
export const voteForCollectionCall = data => {
  const url = `${API_CLOUDFUNCTIONS}/voteForCollectionEntry`
  return call({ method: 'post', url, data })
}

export const getCollectionVotesCall = data => {
  const url = `${API_CLOUDFUNCTIONS}/getCollectionVotesBySlug`
  return call({ method: 'post', url, data })
}

export const sendQuizDataCall = (data, signal) => {
  const url = `${API_CLOUDFUNCTIONS}/emailResults`
  return call({ method: 'post', url, data, signal })
}

export const addPlaidAccountCall = (token, data) => {
  const url = `${API_CONNECT_DOT_APP_ENGINE_URL}/platforms/connect/plaid`
  return call({
    method: 'post',
    url,
    headers: { Authorization: `Bearer ${token}` },
    data
  })
}

export const addPlaidInvestAccountCall = (token, data) => {
  const url = `${API_CONNECT_DOT_APP_ENGINE_URL}/platforms/connect/plaid-invest`
  return call({
    method: 'post',
    url,
    headers: { Authorization: `Bearer ${token}` },
    data
  })
}

export const addFavoriteCall = (token, data) => {
  const url = `${API_CLOUDFUNCTIONS}/addFavorite`
  return call({
    method: 'post',
    url,
    headers: { Authorization: `Bearer ${token}` },
    data
  })
}

export const getFavoriteCall = token => {
  const url = `${API_CLOUDFUNCTIONS}/getFavorite`
  return call({
    method: 'post',
    url,
    headers: { Authorization: `Bearer ${token}` }
  })
}

export const deleteFavoriteCall = (token, data) => {
  const url = `${API_CLOUDFUNCTIONS}/deleteFavorite`
  return call({
    method: 'post',
    url,
    headers: { Authorization: `Bearer ${token}` },
    data
  })
}

// POST

export const updateManualPlatformCall = (token, data, id) => {
  const url = `${API_CONNECT_DOT_APP_ENGINE_URL}/platforms/connect/manual/${id}`
  return call({
    method: 'post',
    url,
    headers: { Authorization: `Bearer ${token}` },
    data
  })
}

export const connectYodleeAccountCall = (token, data) => {
  const url = `${API_CONNECT_DOT_APP_ENGINE_URL}/platforms/connect/yodlee`
  return call({
    method: 'post',
    url,
    headers: { Authorization: `Bearer ${token}` },
    data
  })
}

export const connectErrorCall = (token, data) => {
  const url = `${API_CONNECT_MONEYMADE}/v1/portfolio/connect/error`
  return call({
    method: 'post',
    url,
    headers: { Authorization: `Bearer ${token}` },
    data
  })
}

export const addAccountsCall = (token, data) => {
  const url = `${API_CONNECT_MONEYMADE}/v1/portfolio/accounts`
  return call({
    method: 'post',
    url,
    headers: { Authorization: `Bearer ${token}` },
    data
  })
}

export const hideConnectedPlatformsCall = (token, platformId) => {
  const url = `${API_CONNECT_MONEYMADE}/v1/portfolio/platforms/${platformId}/hide`
  return call({
    method: 'post',
    url,
    headers: { Authorization: `Bearer ${token}` }
  })
}

export const connectPortfolioPlatformCall = (token, data, platformId) => {
  const url = `${API_CONNECT_MONEYMADE}/v2/portfolio/platforms/${platformId}/connect`
  return call({
    method: 'post',
    url,
    headers: { Authorization: `Bearer ${token}` },
    data
  })
}

export const connectMoneymadeAccountCall = (token, data) => {
  const url = `${API_CONNECT_DOT_APP_ENGINE_URL}/platforms/connect/moneymade-connect`
  return call({
    method: 'post',
    url,
    headers: { Authorization: `Bearer ${token}` },
    data
  })
}

export const subscibeWatchlistCall = (token, data) => {
  const url = `${API_WATCHLIST}/api/v1/watchlist/subscribe`
  return call({
    method: 'post',
    url,
    headers: { Authorization: `Bearer ${token}` },
    data
  })
}

export const unsubscibeWatchlistCall = (token, data) => {
  const url = `${API_WATCHLIST}/api/v1/watchlist/unsubscribe`
  return call({
    method: 'post',
    url,
    headers: { Authorization: `Bearer ${token}` },
    data
  })
}

export const postWaitlistCall = token => {
  const url = `${API_CLOUDFUNCTIONS}/joinUserToWaitlist`
  return call({
    method: 'post',
    url,
    headers: { Authorization: `Bearer ${token}` }
  })
}
