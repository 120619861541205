import { combineReducers } from 'redux'

import appReducer from './app/reducer'
import authReducer from './auth/reducer'
import servicesReducer from './services/reducer'
import urlReducer from './url/reducer'
import dashboardReducer from './dashboard/reducer'
import discoverReducer from './discover/reducer'
import hubReducer from './hub/reducer'

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  services: servicesReducer,
  url: urlReducer,
  dashboard: dashboardReducer,
  discover: discoverReducer,
  hub: hubReducer
})

export default rootReducer
