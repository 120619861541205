import { SET_HUB_FIELD, SET_HUB_FIELDS, SET_HUB_ALL, SET_HUB_SWIPPED_CARDS, RESET_HUB } from './types'

const setHubFieldAction = payload => ({
  type: SET_HUB_FIELD,
  payload
})

const setHubFieldsAction = payload => ({
  type: SET_HUB_FIELDS,
  payload
})

const setHubAllAction = payload => ({
  type: SET_HUB_ALL,
  payload
})

const setHubSwippedCardsAction = payload => ({
  type: SET_HUB_SWIPPED_CARDS,
  payload
})

const resetHubAction = () => ({
  type: RESET_HUB
})

export { setHubFieldAction, setHubFieldsAction, setHubAllAction, setHubSwippedCardsAction, resetHubAction }
