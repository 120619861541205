import { SET_APP_FIELD, SET_APP_FIELDS, SET_ASK_EXPERT_POPUP, SET_FOOTER_FIELD } from './types'

const setAppFieldAction = payload => ({
  type: SET_APP_FIELD,
  payload
})

const setAppFieldsAction = payload => ({
  type: SET_APP_FIELDS,
  payload
})

const setFooterFieldAction = payload => ({
  type: SET_FOOTER_FIELD,
  payload
})

const setAskExpertPopupAction = payload => ({
  type: SET_ASK_EXPERT_POPUP,
  payload
})

export { setAppFieldAction, setAppFieldsAction, setFooterFieldAction, setAskExpertPopupAction }
