import { useSelector } from 'react-redux'

import HeaderAuth from './HeaderAuth'

const HeaderAuthContainer = props => {
  const {
    isLogged,
    userData: { userId }
  } = useSelector(({ auth }) => auth)

  const updatedProps = {
    ...props,
    isLogged,
    userId
  }

  return <HeaderAuth {...updatedProps} />
}

export default HeaderAuthContainer
