import { setAskExpertPopupAction } from 'redux/app/actions'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import HeaderMenu from './HeaderMenu'

const HeaderMenuContainer = props => {
  const { isLogged } = useSelector(({ auth }) => auth)
  const { askExpertPopup } = useSelector(({ app }) => app)

  const dispatch = useDispatch()

  const setAskExpertPopup = useCallback(payload => dispatch(setAskExpertPopupAction(payload)), [dispatch])

  const updatedProps = {
    ...props,
    isLogged,
    askExpertPopup,

    handleSetAskExpertPopup: setAskExpertPopup
  }

  return <HeaderMenu {...updatedProps} />
}

export default HeaderMenuContainer
