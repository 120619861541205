import { SET_APP_FIELD, SET_APP_FIELDS, SET_ASK_EXPERT_POPUP, SET_FOOTER_FIELD } from './types'

const initialState = {
  inIframe: false,
  footer: {
    isAdvertiserModal: false,
    discover: [],
    articles: [],
    collection: [],
    trending: [],
    reviews: []
  },
  banners: [],
  askExpertPopup: {
    status: false,
    params: ''
  },
  quiz: {},
  quizMailSent: true,
  quizMailResult: {}
}

const appReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_APP_FIELD:
      return { ...state, [payload.key]: payload.value }

    case SET_APP_FIELDS:
      return payload.reduce((acc, { key, value }) => ({ ...acc, [key]: value }), { ...state })

    case SET_FOOTER_FIELD:
      return { ...state, footer: { ...state.footer, [payload.key]: payload.value } }

    case SET_ASK_EXPERT_POPUP:
      return { ...state, askExpertPopup: { status: payload.status, params: payload.params ? payload.params : '' } }

    default:
      return state
  }
}

export default appReducer
