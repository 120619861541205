import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { GAFBSendEvent, isBrowser } from 'utils'
import ObserverSensor from 'components/app/ObserverSensor/ObserverSensor'
import Observer from 'components/app/Observer/Observer'

const useTracker = () => {
  const { paramsAdded } = useSelector(({ url }) => url)

  const VisibilityTracker = ({ category = '', actionKey = '', action = '', children = null }) =>
    paramsAdded && isBrowser() ? (
      <Observer
        onVisible={isVisible => {
          if (isVisible) {
            GAFBSendEvent(category, actionKey, action)
          }
        }}
      >
        {children}
      </Observer>
    ) : (
      children
    )

  VisibilityTracker.propTypes = {
    category: PropTypes.string,
    actionKey: PropTypes.string,
    action: PropTypes.string,
    children: PropTypes.node
  }

  VisibilityTracker.defaultProps = {
    category: '',
    actionKey: '',
    action: '',
    children: null
  }

  const HoverScrollTracker = ({ category = '', actionKey = '', action = '', children = null }) =>
    paramsAdded && isBrowser() ? (
      <ObserverSensor
        onMouseOver={() => {
          GAFBSendEvent(`${category}MouseOver`, actionKey, action)
        }}
        onScroll={() => {
          GAFBSendEvent(`${category}Scroll`, actionKey, action)
        }}
      >
        {children}
      </ObserverSensor>
    ) : (
      children
    )

  HoverScrollTracker.propTypes = {
    category: PropTypes.string,
    actionKey: PropTypes.string,
    action: PropTypes.string,
    children: PropTypes.node
  }

  HoverScrollTracker.defaultProps = {
    category: '',
    actionKey: '',
    action: '',
    children: null
  }

  const handleUseTracker = useCallback((category, actionKey, action) => {
    GAFBSendEvent(category, actionKey, action)
  }, [])

  const handleUrlSubId = (slug, origin) =>
    `https://w.mmin.io/platform-click/${slug}?utm_source=moneymade&utm_campaign=${origin}&utm_medium=website`

  const handleUrlSubIdUtms = (website, utms) =>
    `${website}?utm_source=${utms.source}&utm_campaign=${utms.campaign}&utm_medium=${utms.medium}&utm_content=${utms.content}`

  return { VisibilityTracker, HoverScrollTracker, handleUseTracker, handleUrlSubId, handleUrlSubIdUtms }
}

export default useTracker
