import { createStore, applyMiddleware } from 'redux'
import { createWrapper } from 'next-redux-wrapper'
import { composeWithDevTools } from 'redux-devtools-extension'

import { REDUX_DEV_TOOLS } from 'static/constants'
import rootReducer from './rootReducer'

// Create redux store
const makeStore = () =>
  createStore(rootReducer, {}, REDUX_DEV_TOOLS ? composeWithDevTools(applyMiddleware()) : applyMiddleware())

// Create wrapper to work with redux on next backend
const wrapper = createWrapper(makeStore, { debug: false })

export default wrapper
