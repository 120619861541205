import dynamic from 'next/dynamic'
import Script from 'next/script'
import 'intersection-observer/intersection-observer'
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
import PropTypes from 'prop-types'

import MainLayout from 'layouts/MainLayout/MainLayoutContainer'
import HeadTags from 'components/app/HeadTags/HeadTags'
import NotFound from 'components/ui/NotFound/NotFound'
import wrapper from 'redux/store'
import 'scss/index.scss'

const InitApp = dynamic(() => import('components/init/InitApp/InitAppContainer'))
const InitTracking = dynamic(() => import('components/init/InitTracking/InitTracking'))

const MyApp = ({ Component, pageProps }) => (
  <>
    <HeadTags title="MoneyMade" description="Do more with your money." />

    <MainLayout>
      <InitApp />
      <InitTracking />

      <Component {...pageProps} />
    </MainLayout>

    {/* Google Tag Manager */}
    <Script
      id="google-tag-managet"
      strategy="beforeInteractive"
      dangerouslySetInnerHTML={{
        __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-TKH2HNF');`
      }}
    />

    {/* End Google Tag Manager */}

    {/* Google Tag Manager (noscript) */}
    <noscript>
      <iframe
        title="google-tag-managet"
        src={`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TKH2HNF" height="0" width="0" style="display:none;visibility:hidden"></iframe>`}
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
      />
    </noscript>
  </>
)

MyApp.propTypes = {
  Component: PropTypes.elementType,
  pageProps: PropTypes.shape({})
}

MyApp.defaultProps = {
  Component: <NotFound />,
  pageProps: {}
}

// Wrapper to use redux on next backend
export default wrapper.withRedux(MyApp)
