import { Icon, MainLink, P3, P4 } from '@moneymade/moneymade-ui'
import { ROUTES } from 'static/menusConstants'
import moment from 'moment'
import Link from 'next/link'
import PropTypes from 'prop-types'

import ImgLazy from 'components/ui/ImgLazy/ImgLazy'
import { getCdnImg } from 'utils/getters'
import styles from './DiscoverModal.module.scss'

const DiscoverModal = ({ investmentsHeader, readHeader, handleClose }) => (
  <>
    <div className={styles.DiscoverModal} onClick={() => handleClose()} aria-hidden />

    <div className={styles.Modal}>
      <div className={styles.Header}>
        <div className={styles.Left}>
          <P3 weight="semibold" className={styles.Title}>
            {`${moment(Date()).format('MMMM')}’s`} Top Platforms
          </P3>
          {investmentsHeader.map(({ name, logo, slug, collapsedDescription }) => (
            <Link href={`/${ROUTES.discover}/${slug}`} key={name}>
              <a className={styles.InvestmentCard} aria-hidden onClick={() => handleClose()}>
                <div className={styles.ImgContainer}>
                  <ImgLazy src={getCdnImg(logo, 20)} alt={name} width={20} height={20} fit="cover" />
                </div>
                <div className={styles.Contant}>
                  <P3>{name}</P3>
                  <P4>{collapsedDescription}</P4>
                </div>
              </a>
            </Link>
          ))}
        </div>
        <div className={styles.Right}>
          <P3 weight="semibold" className={styles.Title}>
            Trending Reads
          </P3>

          {readHeader.map(({ title, slug }) => (
            <Link href={`/${ROUTES.learnArticle}/${slug}`} key={title}>
              <a className={styles.ReadCard} aria-hidden onClick={() => handleClose()}>
                <P3>{title.length > 66 ? `${title.slice(0, 63)}...` : title}</P3>
              </a>
            </Link>
          ))}
        </div>
      </div>
      <div className={styles.Bottom}>
        <MainLink
          href="/discover"
          weight="normal"
          iconAfter={<Icon icon="icon-arrow-right" size={12} />}
          fontType="main"
          size="xs"
          onClick={() => handleClose()}
        >
          Discover All
        </MainLink>
      </div>
    </div>
  </>
)

DiscoverModal.propTypes = {
  investmentsHeader: PropTypes.arrayOf(PropTypes.object),
  readHeader: PropTypes.arrayOf(PropTypes.object),
  handleClose: PropTypes.func
}

DiscoverModal.defaultProps = {
  investmentsHeader: [],
  readHeader: [],
  handleClose: () => {}
}
export default DiscoverModal
