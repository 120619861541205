const SET_DASHBOARD_FIELD = 'dashboard/SET_DASHBOARD_FIELD'
const SET_DASHBOARD_FIELDS = 'dashboard/SET_DASHBOARD_FIELDS'
const ADD_INVESTMENT_TO_FAVORITES = 'dashboard/ADD_INVESTMENT_TO_FAVORITES'
const ADD_INVESTMENTS_TO_FAVORITES = 'dashboard/ADD_INVESTMENTS_TO_FAVORITES'
const REMOVE_INVESTMENT_FROM_FAVORITES = 'dashboard/REMOVE_INVESTMENT_FROM_FAVORITES'
const PREV_BALANCE = 'dashboard/PREV_BALANCE'

export {
  SET_DASHBOARD_FIELD,
  SET_DASHBOARD_FIELDS,
  ADD_INVESTMENT_TO_FAVORITES,
  ADD_INVESTMENTS_TO_FAVORITES,
  REMOVE_INVESTMENT_FROM_FAVORITES,
  PREV_BALANCE
}
