import {
  SET_DISCOVER_FIELD,
  SET_DISCOVER_FIELDS,
  SET_FILTER_OPTION,
  SET_FILTER_CONTAINS_OPTION,
  RESET_ONE_FILTER,
  RESET_SIEVE
} from './types'

const initialState = {
  platformsToShow: 999,
  searchBy: '',
  sortBy: '',
  filterBy: [], // Structure: [{name: '', item: ''}, {name: '', item: ''}...
  filterContains: [] // Structure: ['key1', 'key2', 'key3'...
}

const discoverReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_DISCOVER_FIELD:
      return { ...state, [payload.key]: payload.value }

    case SET_DISCOVER_FIELDS:
      return payload.reduce((acc, { key, value }) => ({ ...acc, [key]: value }), { ...state })

    case SET_FILTER_OPTION: {
      // Search for new filter in current array of filters (only by name skipping item)
      const isFilterApplied = state.filterBy.some(({ name }) => name === payload.name)

      if (payload.multiple) {
        // Find current filter option
        const { item = [] } = state.filterBy.find(({ name }) => name === payload.name) || {}
        // Search for the same filter item
        const isOptionApplied = item.some(filterItem => filterItem === payload.item)
        // Remove option if the option is exist
        const withRemovedOption = isOptionApplied ? item.filter(filterItem => filterItem !== payload.item) : item
        // Replace item for current filter
        const withNewFilter = isFilterApplied
          ? state.filterBy.map(filter =>
              filter.name === payload.name
                ? { ...filter, item: isOptionApplied ? withRemovedOption : [...filter.item, payload.item] }
                : filter
            )
          : [...state.filterBy, { ...payload, item: [payload.item] }]
        // Remove filters with empty item
        const finalFilterBy = withNewFilter.filter(({ item: itemFilter }) => itemFilter.length)

        return { ...state, filterBy: finalFilterBy }
      }
      // Search for the same filter applied
      const isFilterTheSame = state.filterBy.some(filter => JSON.stringify(filter) === JSON.stringify(payload))
      // If new filter already exist remove old filter with the name of new filter
      const withRemovedFilter = isFilterApplied
        ? state.filterBy.filter(({ name }) => name !== payload.name)
        : state.filterBy

      const finalFilterBy = isFilterTheSame ? withRemovedFilter : [...withRemovedFilter, payload]

      return { ...state, filterBy: finalFilterBy }
    }

    case SET_FILTER_CONTAINS_OPTION: {
      // Search for new filter in current array of filters
      const isFilterContainsApplied = state.filterContains.some(filter => filter === payload)
      // If new filter already exist remove old filter or if not - add new one
      const finalFilterContains = isFilterContainsApplied
        ? state.filterContains.filter(filter => filter !== payload)
        : [...state.filterContains, payload]

      return { ...state, filterContains: finalFilterContains }
    }

    case RESET_ONE_FILTER: {
      const filterByFiltered = state.filterBy.filter(({ name }) => name !== payload)

      return { ...state, filterBy: filterByFiltered }
    }

    case RESET_SIEVE:
      return { ...initialState }

    default:
      return state
  }
}

export default discoverReducer
