import { isBrowser, isLocalStorage } from './helpers'

/**
 * Sets data to the local storage
 * @param {string} key
 * @param {*} value
 */
export const setLSData = (key, value) => {
  if (isLocalStorage()) localStorage.setItem(key, JSON.stringify(value))
}

/**
 * Gets data from the local storage
 * @param {string} key
 */
export const getLSData = key => {
  if (isLocalStorage()) JSON.parse(localStorage.getItem(key))
}

/**
 * Removes data from the local storage
 * @param {string} key
 */
export const removeLSData = key => {
  if (isLocalStorage()) localStorage.removeItem(key)
}

/**
 * Gets previous page position
 * @param {string} page
 * @returns
 */
export const getPrevPos = page => {
  const prevPos = window[`${page}PrevPos`] || 0
  window[`${page}PrevPos`] = 0

  return prevPos
}

/**
 * Saves current scroll position on page
 * @param {string} page
 * @param {number} newPosition
 */
export const setPrevPos = (page, newPosition) => {
  window[`${page}PrevPos`] = newPosition
}

// Get recorded statistic. Used for send statistic packs
export const getStatistic = () => (isBrowser() ? window.statistic || {} : {})

// Record statistic. Used for 'ev'
export const recordStatistic = (source, dataObj) => {
  const statisticData = getStatistic()

  if (isBrowser()) {
    const statisticType = statisticData[source] || []
    statisticData[source] = [...statisticType, dataObj]

    window.statistic = statisticData
  }
}

// Clear statistic. Used after sent 'ev' action
export const clearStatistic = () => {
  window.statistic = undefined
}
