import { useSelector } from 'react-redux'

import UserAvatar from './UserAvatar'

const UserAvatarContainer = props => {
  const {
    userData: { nickname, email, avatar }
  } = useSelector(({ auth }) => auth)

  const updatedProps = {
    ...props,
    nickname,
    email,
    avatar
  }

  return <UserAvatar {...updatedProps} />
}

export default UserAvatarContainer
