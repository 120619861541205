import moment from 'moment'
import * as Sentry from '@sentry/nextjs'

/**
 * Send Sentry error
 * @param {string} errorText
 * @param {boolean} throwError
 */
export const throwSentryError = (errorText = 'Error: unknown', throwError = true) => {
  Sentry.captureException(errorText)
  if (throwError) throw new Error(errorText)
}

/**
 * Checks whether local storage is available
 * @returns {boolean}
 */
export const isLocalStorage = () => {
  try {
    if (typeof localStorage === 'object' && navigator.cookieEnabled) return true
    return false
  } catch (e) {
    return false
  }
}

/**
 * Checks whether fromnent or backend
 * @returns {boolean}
 */
export const isBrowser = () => typeof window !== 'undefined'

/**
 * Checks whether the link is an external URL
 * @param {string} link
 * @returns {string}
 */
export const isExternalUrl = link =>
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g.test(
    link
  )

/**
 * Convert string to the camel case format
 * @param {string} string
 * @param {boolean} [saveSpace] Whether save space between words after convert
 * @returns {string}
 */
export const toCamelCase = (string, saveSpace) => {
  if (typeof string !== 'string') return string

  // Get each word separately
  let words = string.split(' ')
  // Conbert each word to the right case
  words = words.map((word, index) =>
    index !== 0 ? `${word.charAt(0).toUpperCase()}${word.toLocaleLowerCase().slice(1)}` : word.toLowerCase()
  )
  // Join to one string without spaces
  words = words.join(saveSpace ? ' ' : '')

  return words
}

/**
 * Convert string to the capitalize format
 * @param {string} string
 * @param {boolean} [saveSpace] Whether save space between words after convert
 * @returns {string}
 */
export const toCapitalize = (string, saveSpace) => {
  if (typeof string !== 'string') return string

  // Get each word separately
  let words = string.split(' ')
  // Conbert each word to the right case
  words = words.map(word => `${word.charAt(0).toUpperCase()}${word.toLocaleLowerCase().slice(1)}`)
  // Join to one string without spaces
  words = words.join(saveSpace ? ' ' : '')

  return words
}

/**
 * Sort array ASC (including an array of objects)
 * @param {*[]} items
 * @param {string} [key]
 * @returns {array}
 */
export const sortAsc = (items, key) => {
  if (key)
    return items.sort((a, b) =>
      typeof a[key] === 'string' && typeof b[key] === 'string' ? a[key].localeCompare(b[key]) : a[key] - b[key]
    )

  return items.sort((a, b) => (typeof a === 'string' && typeof b === 'string' ? a.localeCompare(b) : a - b))
}

/**
 * Sort array DESC (including an array of objects)
 * @param {*[]} items
 * @param {string} [key]
 * @returns {array}
 */
export const sortDesc = (items, key) => {
  if (key)
    return items.sort((a, b) =>
      typeof a[key] === 'string' && typeof b[key] === 'string' ? b[key].localeCompare(a[key]) : b[key] - a[key]
    )

  return items.sort((a, b) => (typeof a === 'string' && typeof b === 'string' ? b.localeCompare(a) : b - a))
}

/**
 * Convert e- in a normal number
 * @param {number} value
 * @returns {string}
 */
export const toBigInt = value => {
  const e = parseInt(value.toString().split('e-')[1], 10)

  if (e) {
    const powerNumber = value * 10 ** (e - 1)
    const fullNumber = `0.${new Array(e).join('0')}${powerNumber.toString().substring(2)}`

    return fullNumber
  }

  return String(value)
}

/**
 * Converta number to the money format
 * @param {number} value
 * @param {number} [roundTo]
 * @returns {string}
 */
export const toMoneyFormat = (value, roundTo = 2) =>
  Number(value)
    ?.toFixed(roundTo)
    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',') || value

/**
 * Convert a number with a lot of decimals to a format: 0.0...123
 * @param {number} value
 * @param {number} [roundTo]
 * @returns {string}
 */
export const toTintMoneyFormat = (value, roundTo) => {
  if (value <= 0) return '0'

  // Return default number if more than 1
  if (value >= 0.1) return toMoneyFormat(value, roundTo)

  // If value is less than 0.1 and fractions length is less than 5
  if (String(value).length <= 7) return String(value)

  const fullNumber = toBigInt(value)
  // Get first number after 0.
  const firstFractionNumber = String(fullNumber).split('.')[1].charAt(0)
  // Get last 4 numbers
  const lastNumbers = String(fullNumber).slice(-4)

  return `0.${firstFractionNumber}...${lastNumbers}`
}

/**
 * Convert a number to the money format with + or - before the number
 * @param {number} value
 * @param {number} [roundTo]
 * @returns {string}
 */
export const toPosOrNeg = (value, roundTo) =>
  value < 0 ? `${toMoneyFormat(value, roundTo)}` : `+${toMoneyFormat(value, roundTo)}`

/**
 * Convert a huge number to a number with K/M/B/T sign near
 * @param {number} value
 * @param {number} [roundTo]
 * @returns {string}
 */
export const toMoneyFormatWithLetter = (value, roundTo) => {
  if (value < 1e3) return value.toFixed(Number.isInteger(roundTo) ? roundTo : 1)
  if (value >= 1e3 && value < 1e6) return `${+(value / 1e3).toFixed(Number.isInteger(roundTo) ? roundTo : 1)}K`
  if (value >= 1e6 && value < 1e9) return `${+(value / 1e6).toFixed(Number.isInteger(roundTo) ? roundTo : 1)}M`
  if (value >= 1e9 && value < 1e12) return `${+(value / 1e9).toFixed(Number.isInteger(roundTo) ? roundTo : 1)}B`
  if (value >= 1e12) return `${+(value / 1e12).toFixed(Number.isInteger(roundTo) ? roundTo : 1)}T`

  return value.toFixed(Number.isInteger(roundTo) ? roundTo : 2)
}

/**
 * Remove HTML tags from the string
 * @param {string} string
 * @returns {string}
 */
export const removeHTMLTags = string => {
  if (typeof string === 'string') {
    const withoutHtml = string.replace(/(<([^>]+)>)/gi, '')
    return withoutHtml.trim()
  }
  return string.trim()
}

/**
 * Filter an array with objects by a timeframe
 * @param {object[]} values Each object have to contain the 'datetime' key
 * @param {'24H'|'5D'|'7D'|'30D'|'1Y'|'5Y'|'10Y'} timeframe
 * @returns {object[]}
 */
export const getDateData = (values, timeframe) => {
  if (!values.length) return values

  const dateFormat = `YYYY-MM-DD HH:${timeframe === '24H' ? 'mm:ss' : '00:00'}`
  const date = moment(values[0].datetime, dateFormat)

  switch (timeframe) {
    case '24H': {
      // Substract 1 days from current datetime
      date.subtract(1, 'days')
      break
    }

    case '5D': {
      // Substract 5 days from current datetime
      date.subtract(5, 'days')
      break
    }

    case '7D': {
      // Substract 7 days from current datetime
      date.subtract(7, 'days')
      break
    }

    case '30D': {
      // Substract 1 month from current datetime
      date.subtract(1, 'months')
      break
    }

    case 'YTD': {
      // Get first day and first month of the year
      date.startOf('year')
      break
    }

    case '1Y': {
      // Substract 1 year from current datetime
      date.subtract(1, 'years')
      break
    }

    case '5Y': {
      // Substract 5 year from current datetime
      date.subtract(5, 'years')
      break
    }

    case '10Y': {
      // Substract 10 year from current datetime
      date.subtract(10, 'years')
      break
    }

    default:
      break
  }

  // Current date in moment format
  const toDate = moment(moment().format(dateFormat), dateFormat)
  // Filter date form date in the 'date' variable to date in the 'toDate' variable
  const filteredValues = values.filter(({ datetime }) => moment(datetime, dateFormat).isBetween(date, toDate))

  return filteredValues
}

/**
 * Get an expiration date of a JWT token
 * @param {string} token
 * @returns {number|null}
 */
// @Ruslan - replace atob (deprecated). And rename c variable to appropriate name
export const getExpirationDate = token => {
  if (token.split('.')[1]) {
    const base64 = token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    )
    // Get JWT encoded token
    const jwt = JSON.parse(jsonPayload)
    // Convert UNIX time to date time
    return jwt?.exp ? jwt.exp * 1000 : null
  }

  return null
}
