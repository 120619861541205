import { useEffect, useState } from 'react'
import { P1, Icon } from '@moneymade/moneymade-ui'
import PropTypes from 'prop-types'
import { useTransition, animated } from 'react-spring'
import Link from 'next/link'
import { createPortal } from 'react-dom'

import { isLocalStorage, isExternalUrl } from 'utils'
import { getCtaCall } from 'api/calls'
import styles from './GlobalCta.module.scss'

// Get White List for auth
const getStatusClickedMore = () => {
  const globalCta = false

  if (!isLocalStorage()) return globalCta

  const serializedState = localStorage.getItem('GlobalCta')
  return Boolean(serializedState) || globalCta
}

// Set White List
const setStatusClickedMore = globalCta => isLocalStorage() && localStorage.setItem('GlobalCta', globalCta)

const GlobalCta = ({ isLogged, isAuthChecked, pathname }) => {
  const [isModal, setModal] = useState(true)
  const [dataCta, setDataCta] = useState({})

  // Status for click on button in modal  and write to localstore
  const [statusWriteToStore, setStatusWriteToStore] = useState(getStatusClickedMore())

  const isAboutPortfolio = pathname.includes('/about-portfolio')

  const handelChangeStatusClickedMore = () => {
    setStatusWriteToStore(true)
    setStatusClickedMore(true)
  }

  const transition = useTransition(isModal, {
    from: { transform: 'translateX(100%)' },
    enter: { transform: 'translateX(0%)' },
    leave: { transform: 'translateX(100%)' },
    config: {
      duration: 200
    }
  })

  useEffect(() => {
    if (isAuthChecked && !isLogged) {
      const handleCtaCall = async () => {
        const { success, result } = await getCtaCall('cta')()

        // result?.success !== false Need for empty CMS results

        if (success && result?.enabled) setTimeout(() => setDataCta(result), 10000)
      }
      handleCtaCall()
    }
  }, [isLogged, isAuthChecked])

  return (
    !statusWriteToStore &&
    !isAboutPortfolio &&
    !isLogged &&
    !!Object.keys(dataCta).length &&
    transition(
      (springStyles, isItem) =>
        isItem &&
        createPortal(
          <animated.div style={springStyles} className={styles.GlobalCta}>
            <div
              className={styles.GlobalCtaContainer}
              style={{ backgroundColor: dataCta?.backgroundColor || '#fffff' }}
            >
              <button type="button" onClick={() => setModal(false)} className={styles.CloseBtn}>
                <Icon icon="icon-x-mark" size={14} />
              </button>
              <img src={dataCta?.image} alt="img" className={styles.HeaderImg} />
              <P1 className={styles.Description} style={{ color: dataCta?.textColor }}>
                {dataCta?.title}
              </P1>

              {isExternalUrl(dataCta?.link) ? (
                <a
                  href={dataCta?.link}
                  className={styles.SubmitButton}
                  target="_blank"
                  rel="noreferrer"
                  onClick={handelChangeStatusClickedMore}
                >
                  <P1 onClick={handelChangeStatusClickedMore}>{dataCta?.buttonText} </P1>
                  <Icon icon="icon-arrow-right" size={16} />
                </a>
              ) : (
                <Link href={dataCta?.link}>
                  <a className={styles.SubmitButton}>
                    <P1 onClick={handelChangeStatusClickedMore}>{dataCta?.buttonText} </P1>
                    <Icon icon="icon-arrow-right" size={16} />
                  </a>
                </Link>
              )}
            </div>
          </animated.div>,
          document.body
        )
    )
  )
}

GlobalCta.propTypes = {
  isAuthChecked: PropTypes.bool,
  isLogged: PropTypes.bool,
  pathname: PropTypes.string
}

GlobalCta.defaultProps = {
  isAuthChecked: true,
  isLogged: false,
  pathname: ''
}

export default GlobalCta
