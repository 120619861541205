import { useRouter } from 'next/router'
import { useMemo, useEffect } from 'react'
import NextNprogress from 'nextjs-progressbar'
import ScrollToTop from 'react-scroll-to-top'
import PropTypes from 'prop-types'
import { Icon } from '@moneymade/moneymade-ui'
import { SSRProvider, SearchProvider, FieldDictionary, Pipeline } from '@sajari/react-search-ui'

import { ROUTES, WHITE_LIST_MONEYBAR } from 'static/menusConstants'
import Header from 'components/header/Header/HeaderContainer'
import MobHeader from 'components/header/MobHeader/MobHeaderContainer'
import Footer from 'components/footer/Footer/FooterContainer'
import GlobalCta from 'components/ads/GlobalCta/GlobalCtaConatiner'
import styles from './MainLayout.module.scss'

const isUrl = (url = '/', match) => url.includes(match)

const MainLayout = ({ children, inIframe }) => {
  // To detect homepage
  const { pathname, asPath } = useRouter()

  const { isOnboarding, isDashboard, isTransparent, isTransparentMob } = useMemo(() => {
    const onboadrind = pathname === `/${ROUTES.onboarding}`
    const dashboard = pathname.includes(`/${ROUTES.dashboard}`)
    const transparentMob = pathname.includes(`/${ROUTES.assetPage}`)
    const transparent =
      pathname.includes(`/${ROUTES.aboutPortfolio}`) ||
      pathname.includes(`/${ROUTES.forYou}`) ||
      pathname.includes(`/${ROUTES.assetPage}`) ||
      pathname.includes(`/${ROUTES.learnAuthor}`)

    return {
      isOnboarding: onboadrind,
      isDashboard: dashboard,
      isTransparentMob: transparentMob,
      isTransparent: transparent
    }
  }, [pathname])
  const landing = useMemo(() => isUrl(pathname, `/${ROUTES.landing}`), [pathname])

  const pipeline = new Pipeline(
    {
      account: '1661315501121740704',
      collection: 'moneymade-crawl',
      endpoint: '//jsonapi-us-valkyrie.sajari.net',
      pipeline: 'website',
      preset: 'website',
      currency: 'USD',
      variables: {},
      filters: [],
      options: {
        sorting: {
          options: [
            {
              name: 'Most relevant',
              value: ''
            },
            {
              name: 'Alphabetical: A to Z',
              value: 'title'
            },
            {
              name: 'Alphabetical: Z to A',
              value: '-title'
            },
            {
              name: 'Date: Oldest to Newest',
              value: 'published_time'
            },
            {
              name: 'Date: Newest to Oldest',
              value: '-published_time'
            }
          ]
        },
        results: {
          viewType: 'list',
          showVariantImage: false,
          imageAspectRatio: {
            grid: 1,
            list: 1.6
          },
          imageObjectFit: {
            grid: 'cover',
            list: 'contain'
          }
        },
        showViewType: true,
        input: {
          hide: false,
          position: 'top'
        },
        mode: 'overlay',
        buttonSelector: "a[href='/search']"
      },
      theme: {
        color: {
          primary: {
            base: '#667eea',
            text: '#ffffff',
            active: '#5a67d8'
          }
        }
      }
    },
    'website'
  )

  const moneyBarShow = useMemo(() => WHITE_LIST_MONEYBAR.map(slug => isUrl(pathname, slug)).includes(true), [pathname])

  useEffect(() => {
    const moneyBars = document.querySelectorAll('.money-made-embed[data-embed-widget="money-bar"]')
    if (!moneyBarShow) {
      const combined = [...moneyBars]
      // eslint-disable-next-line no-restricted-syntax
      for (const element of combined) {
        element.remove()
      }
    }
    if (moneyBarShow && moneyBars.length < 1 && pathname !== '/learn/article/[slug]') {
      const moneyBarElement = document.createElement('div')
      moneyBarElement.id = 'money-bar-4b53191f-e337-453a-8889-7eeeee112a3d'
      moneyBarElement.className = 'money-made-embed'
      moneyBarElement.setAttribute('data-name', 'Money Bar')
      moneyBarElement.setAttribute('data-width', '100%')
      moneyBarElement.setAttribute('data-height', '0')
      moneyBarElement.setAttribute('data-embed-widget', 'money-bar')
      moneyBarElement.setAttribute('data-params', 'platform=masterworks')
      moneyBarElement.setAttribute('data-utm-medium', 'REPLACE_WITH_PAGE_SLUG')
      moneyBarElement.setAttribute('data-utm-source', 'moneymadeio')
      moneyBarElement.setAttribute('data-utm-campaign', 'moneyBar')
      moneyBarElement.setAttribute('data-move-to', 'bottom-fixed')
      moneyBarElement.style.display = 'block'

      // Append the money bar element to the end of the body
      document.body.appendChild(moneyBarElement)

      // Cleanup function to remove the money bar element on component unmount
      return () => {
        // if (moneyBars) {
        //   document.body?.removeChild(moneyBarElement)
        // }
      }
    }
    if (moneyBars.length > 0) {
      moneyBars.forEach((moneyBar, index) => {
        if (index !== 0) {
          moneyBar.remove()
        }
      })
    }
  }, [asPath, moneyBarShow, pathname])

  return (
    <>
      <NextNprogress color="#ed2348" startPosition={0} stopDelayMs={200} height={2} options={{ showSpinner: false }} />

      <SSRProvider>
        <SearchProvider
          search={{
            pipeline,
            fields: new FieldDictionary({
              title: 'name',
              subtitle: data => data.level4 || data.level3 || data.level2 || data.level1 || data.brand
            })
          }}
          searchOnLoad
        >
          {!inIframe && (
            <>
              <Header
                beforeScroll={{
                  primaryColor: isTransparent ? 'dark' : 'red',
                  textColor: isTransparent ? 'light' : 'dark',
                  transparent: isTransparent
                }}
                landing={landing}
              />

              {!isOnboarding && <MobHeader transparent={isTransparentMob} />}
            </>
          )}
        </SearchProvider>
      </SSRProvider>

      <main className={`${!isTransparent ? styles.HeaderPadding : ''} main-content`}>{children}</main>

      <GlobalCta pathname={asPath} />

      {!isDashboard && (
        <ScrollToTop className={styles.ToTopBtn} top={300} component={<Icon icon="icon-point-up" size={10} />} smooth />
      )}

      {!inIframe && !isDashboard && <Footer landing={landing} />}
    </>
  )
}

MainLayout.propTypes = {
  children: PropTypes.node,
  inIframe: PropTypes.bool
}

MainLayout.defaultProps = {
  children: '',
  inIframe: false
}

export default MainLayout
