import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

const useOnScreen = (ref, rootMargin, threshold) => {
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const element = ref.current

    const options = {
      rootMargin: rootMargin || '0px',
      threshold: threshold || 1
    }

    // Track element viewport status
    const observer = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting)
    }, options)

    if (element) {
      observer.observe(element)
    }

    return () => {
      if (element) {
        observer.unobserve(element)
      }
    }
  }, [ref, rootMargin, threshold])

  return isIntersecting
}

useOnScreen.propTypes = {
  threshold: PropTypes.number,
  rootMargin: PropTypes.string,
  ref: PropTypes.node
}

useOnScreen.defaultProps = {
  threshold: 0,
  rootMargin: '',
  ref: null
}

export default useOnScreen
