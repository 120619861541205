import { MANUAL_ACCOUNT_INIT } from 'static/dashboardConstants'

import {
  SET_DASHBOARD_FIELD,
  SET_DASHBOARD_FIELDS,
  ADD_INVESTMENT_TO_FAVORITES,
  ADD_INVESTMENTS_TO_FAVORITES,
  REMOVE_INVESTMENT_FROM_FAVORITES,
  PREV_BALANCE
} from './types'

const initialState = {
  favoritePlatforms: [],
  favoriteAdded: false,
  recommended: [],
  quizDone: false,
  connectAccountModal: false,
  enterAmountModal: false,
  activePlatform: null,
  totalDataRaw: {},
  totalData: [],
  investmentData: [],
  balances: {},
  portfolioData: [],
  selectedAccounts: [],
  allAccounts: [],
  filterBy: 'ALL',
  average: null,
  connectedAccountStatus: false,
  platformId: null,
  amountValue: null,
  filter: 'Total Investment',
  prevBalance: [],
  answers: {},
  calculator: false,
  quizStep: 1,
  intro: true,
  accountsTab: 1,
  manualAccountSchemas: [],
  manualAccount: MANUAL_ACCOUNT_INIT,
  otherAccountModal: false,
  newAccount: null,
  updatingAccounts: false,
  moveAccountModal: false,
  nudgeModal: false,
  ytdCategories: {},
  chooseCategory: false
}

const dashboardReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_DASHBOARD_FIELD:
      return { ...state, [payload.key]: payload.value }

    case SET_DASHBOARD_FIELDS:
      return payload.reduce((acc, { key, value }) => ({ ...acc, [key]: value }), { ...state })

    case ADD_INVESTMENT_TO_FAVORITES:
      return { ...state, favoritePlatforms: [payload, ...state.favoritePlatforms] }

    case ADD_INVESTMENTS_TO_FAVORITES:
      return { ...state, favoritePlatforms: payload }

    case REMOVE_INVESTMENT_FROM_FAVORITES:
      return {
        ...state,
        favoritePlatforms: state.favoritePlatforms.filter(item => item !== payload)
      }

    case PREV_BALANCE:
      return { ...state, prevBalance: [...state.prevBalance, payload] }

    default:
      return state
  }
}

export default dashboardReducer
