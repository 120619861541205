import PropTypes from 'prop-types'

import styles from './ExpertModal.module.scss'

const ExpertModal = ({ params, handleClose }) => (
  <>
    <div className={styles.ExpertModal} onClick={() => handleClose()} aria-hidden />

    <div className={styles.Modal}>
      <div
        id="askExpert-4bf5cbe5-4ebe-422f-9e2b-c65e694e0bae"
        className="money-made-embed"
        data-name="Ask An Expert"
        data-width="335"
        data-embed-widget="askExpert"
        data-params={params}
        data-utm-medium="REPLACE_WITH_PAGE_SLUG"
        style={{ display: 'block' }}
      />
    </div>
  </>
)

ExpertModal.propTypes = {
  params: PropTypes.string,
  handleClose: PropTypes.func
}

ExpertModal.defaultProps = {
  params: '',
  handleClose: () => {}
}
export default ExpertModal
