import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setUserFieldsAction } from 'redux/auth/actions'
import { setAskExpertPopupAction } from 'redux/app/actions'
import Header from './Header'

const HeaderContainer = props => {
  const { isLogged } = useSelector(({ auth }) => auth)
  const { askExpertPopup } = useSelector(({ app }) => app)
  const { readHeader, pratformsHeader } = useSelector(({ services }) => services)

  const dispatch = useDispatch()

  const setUserFields = useCallback(payload => dispatch(setUserFieldsAction(payload)), [dispatch])
  const setAskExpertPopup = useCallback(payload => dispatch(setAskExpertPopupAction(payload)), [dispatch])

  const updatedProps = {
    ...props,
    isLogged,
    askExpertPopup,
    pratformsHeader,
    readHeader,
    handleSetUserFields: setUserFields,
    handleSetAskExpertPopup: setAskExpertPopup
  }

  return <Header {...updatedProps} />
}

export default HeaderContainer
