// App possible routes
const ROUTES = {
  discover: 'discover',
  investments: 'investments',
  learn: 'learn',
  learnArticle: 'learn/article',
  learnArticleQA: 'learn/article-questions-answers',
  learnPlatformReview: 'learn/platform-review',
  learnReview: 'learn/review',
  learnCollection: 'learn/collection',
  learnReviewVideo: 'learn/video',
  learnAuthor: 'learn/author',
  collection: 'collection',
  collections: 'collections',
  home: '',
  trending: 'trending',
  investorTips: 'investortips',
  reviews: 'reviews',
  trading: 'trading',
  share: 'share',
  aboutUs: 'about-us',
  termsofUse: 'terms-of-use',
  termsAndConditionPublisher: 'publisher-terms-and-condition',
  aboutPortfolio: 'about-portfolio',
  partners: 'partner-with-moneymade',
  onboarding: 'onboarding',
  forYou: 'for-you',
  connectApi: 'connect-api',
  today: 'today',
  todayDailyDose: 'today/daily-dose',
  todayNews: 'today/news',
  assetPage: 'asset',
  login: 'login',
  signup: 'signup',
  userSettings: 'profile/settings',
  dashboard: 'dashboard',
  portfolio: 'dashboard/portfolio',
  favorites: 'dashboard/favorites',
  newPlatforms: 'dashboard/new-platforms',
  moneyMakers: 'dashboard/money-makers',
  matchMe: 'match-me',
  landing: 'landing',
  submitProduct: 'submit-product'
}

const WHITE_LIST_MONEYBAR = [
  `/${ROUTES.assetPage}`,
  `/${ROUTES.trending}`,
  `/${ROUTES.learnArticle}`,
  `/${ROUTES.learnReview}`,
  `/${ROUTES.learnCollection}`,
  `/${ROUTES.collections}`,
  `/${ROUTES.discover}`,
  `/404`
]

// App header menu
const HEADER_MENU = [
  {
    id: '0',
    label: 'Discover',
    link: `/${ROUTES.discover}`,
    acceptedLinks: [`/${ROUTES.discover}`, `/${ROUTES.investments}`, `/${ROUTES.assetPage}`],
    icon: 'https://assets.moneymade.io/images/app/Discover.png'
  },
  {
    id: '1',
    label: 'Trending',
    link: `/${ROUTES.trending}`,
    acceptedLinks: [`/${ROUTES.trending}`],
    icon: 'https://assets.moneymade.io/images/app/Trending.png'
  },

  {
    id: '2',
    label: 'Reads',
    link: `/${ROUTES.learnArticle}`,
    acceptedLinks: [`/${ROUTES.learn}`, `/${ROUTES.collection}`],
    icon: 'https://assets.moneymade.io/images/home/reads-menu.svg'
  },
  {
    id: '3',
    label: 'MoneyMatch',
    link: `/${ROUTES.matchMe}`,
    acceptedLinks: [`/${ROUTES.matchMe}`],
    icon: 'https://assets.moneymade.io/images/app/MatchMe.png'
  },
  {
    id: '4',
    label: 'Submit Product',
    link: `/${ROUTES.submitProduct}`,
    acceptedLinks: [`/${ROUTES.submitProduct}`],
    icon: 'https://assets.moneymade.io/images/app/MatchMe.png'
  }
]

const HEADER_MENU_DESKTOP = [
  {
    id: '0',
    label: 'Discover',
    link: `/${ROUTES.discover}`,
    acceptedLinks: [`/${ROUTES.discover}`, `/${ROUTES.investments}`, `/${ROUTES.assetPage}`],
    icon: 'https://assets.moneymade.io/images/app/Trending.png'
  },
  {
    id: '1',
    label: 'Trending',
    link: `/${ROUTES.trending}`,
    acceptedLinks: [`/${ROUTES.trending}`],
    icon: 'https://assets.moneymade.io/images/app/Trending.png'
  },
  {
    id: '2',
    label: 'Reads',
    link: `/${ROUTES.learnArticle}`,
    acceptedLinks: [`/${ROUTES.learn}`, `/${ROUTES.collection}`],
    icon: 'https://assets.moneymade.io/images/home/reads-menu.svg'
  },
  {
    id: '3',
    label: 'MoneyMatch',
    link: `/${ROUTES.matchMe}`,
    acceptedLinks: [`/${ROUTES.matchMe}`],
    icon: 'https://assets.moneymade.io/images/app/MatchMe.png'
  },
  {
    id: '4',
    label: 'Submit Product',
    link: `/${ROUTES.submitProduct}`,
    acceptedLinks: [`/${ROUTES.submitProduct}`],
    icon: 'https://assets.moneymade.io/images/app/MatchMe.png'
  }
]

// App footer menu
const FOOTER_MENU = [
  { id: '0', label: 'Contact', link: 'mailto:hello@moneymade.io' },
  { id: '1', label: 'Advertise', link: '/advertise' },
  { id: '2', label: 'Content Captain', externalLink: 'https://contentcaptain.io' },
  { id: '3', label: 'About Us', link: `/${ROUTES.aboutUs}` },
  { id: '4', label: 'Privacy policy', link: `/${ROUTES.termsofUse}#privacypolicy` },
  { id: '5', label: 'Terms & Conditions', link: `/${ROUTES.termsofUse}` },
  { id: '6', label: 'Submit Product', link: `/${ROUTES.submitProduct}` }
]

const PASSWORD_ERROR =
  'To be secured password it must have at least 8 symbols: at least 1 number (0-9), 1 upper case symbol (A-Z), 1 lower case symbol (a-z), 1 unique symbol (!,@,#,$,%,^,&,*,_,-)'

export { ROUTES, HEADER_MENU, HEADER_MENU_DESKTOP, FOOTER_MENU, PASSWORD_ERROR, WHITE_LIST_MONEYBAR }
