import { useSelector } from 'react-redux'

import GlobalCta from './GlobalCta'

const GlobalCtaConatiner = props => {
  const { isLogged, isAuthChecked } = useSelector(({ auth }) => auth)

  const updatedProps = {
    ...props,
    isLogged,
    isAuthChecked
  }

  return <GlobalCta {...updatedProps} />
}

export default GlobalCtaConatiner
