import { SET_USER_FIELD, SET_USER_FIELDS, SET_USER_DATA_FIELD, SET_USER_DATA_FIELDS, LOGOUT_USER } from './type'

const initialState = {
  isAuthChecked: false,
  isLogged: false,
  isAuthModal: false,
  authModalType: 'signUp', // Can be: welcome, signUp, signIn, forgotPass, forgotPassSent
  userData: {
    userId: '',
    email: '',
    nickname: '',
    firstName: '',
    lastName: '',
    avatar: '',
    zip: '',
    birthday: '',
    token: '',
    watchList: [],
    expirationDate: 0,
    registeredDate: ''
  }
}

const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_USER_FIELD:
      return { ...state, [payload.key]: payload.value }

    case SET_USER_FIELDS:
      return payload.reduce((acc, { key, value }) => ({ ...acc, [key]: value }), { ...state }) || state

    case SET_USER_DATA_FIELD:
      return { ...state, userData: { ...state.userData, [payload.key]: payload.value } }

    case SET_USER_DATA_FIELDS:
      return payload.reduce((acc, { key, value }) => ({ ...acc, userData: { ...acc.userData, [key]: value } }), {
        ...state
      })

    case LOGOUT_USER:
      return { ...initialState, isAuthChecked: true }

    default:
      return state
  }
}

export default authReducer
