// Objectives for discover page
const OBJECTIVES = {
  passiveIncome: {
    platforms: null,
    icon: 'https://assets.moneymade.io/images/InvestObjective/PassiveIncome.svg',
    name: `Passive Income`,
    description: `Create an attractive, consistent income stream`
  },
  balancedInvesting: {
    platforms: null,
    icon: 'https://assets.moneymade.io/images/InvestObjective/BalancedInvesting.svg',
    name: `Balanced Investing`,
    description: `Build wealth confidently with high diversification`
  },
  longTermWorth: {
    platforms: null,
    icon: 'https://assets.moneymade.io/images/InvestObjective/LongTermGrowth.svg',
    name: `Long Term Growth`,
    description: `Pursue superior overall returns over the long term`
  }
}

// Sort options for discover page
const SORT_SIEVE = ['Fees', 'Min investment (low)', 'Min investment (high)', 'Rating']

const FILTER_SIEVE_COLLAPSED = [
  {
    label: 'Your Goal',
    name: 'objectives',
    multiple: true,
    items: ['Passive Income', 'Balanced Investing', 'Long Term Growth']
  },
  // Availability can be only multiple coz based on 2 keys in the investments array
  {
    label: 'Availability',
    name: 'availability',
    multiple: true,
    items: ['Outside US', 'Non-Accredited Investors', 'Accredited Only']
  }
]

const FILTER_SIEVE_EXPANDE = [
  {
    label: 'Risk Score',
    name: 'newRisks',
    multiple: true,
    items: ['Low risk', 'Medium risk', 'High risk']
  },
  {
    label: 'Liquidity',
    name: 'liquidity',
    multiple: true,
    items: ['Easy', 'Moderate', 'Hard']
  },
  {
    label: 'Returns',
    name: 'howMoney',
    multiple: true,
    items: ['Interest', 'Dividends + Value', 'Value', 'Dividends']
  },
  {
    label: 'Payout',
    name: 'payoutFrequency',
    multiple: true,
    items: ['Daily', 'Monthly', 'Quarterly', 'Annually', 'Asset sold']
  }
]

// Filter options for discover page
const FILTER_SIEVE = [...FILTER_SIEVE_COLLAPSED, ...FILTER_SIEVE_EXPANDE]

const CATEGORIES = {
  article: 'article',
  articleQuestionsAnswers: 'articleQuestionsAnswers',
  collection: 'collection'
}

const ACCREDITED = {
  accredited: 'Accredited Only',
  noAccredited: 'All Investors',
  accreditedTooltip: 'Accredited Investors Only',
  noAccreditedTooltip: 'Open to All Investors'
}

export { OBJECTIVES, SORT_SIEVE, FILTER_SIEVE_COLLAPSED, FILTER_SIEVE_EXPANDE, FILTER_SIEVE, CATEGORIES, ACCREDITED }
