import PropTypes from 'prop-types'
import { Avatar, P1 } from '@moneymade/moneymade-ui'

import styles from './UserAvatar.module.scss'

const UserAvatar = ({ width, height, fontSize, nickname, email, avatar, needName }) => {
  const handleGetAbbreviation = name =>
    name
      .trim()
      .toLowerCase()
      .split(' ')
      .map(letter => letter[0].toUpperCase())
      .reduce((acc, letter) => `${acc}${letter}`)

  return avatar ? (
    <div className={styles.UserAvatar}>
      <Avatar src={avatar} alt={nickname || 'avatar'} width={width} height={height} />
      {needName && nickname && <P1 weight="semibold">{nickname}</P1>}
    </div>
  ) : (
    <div>
      <Avatar width={width} height={height} fontSize={fontSize}>
        {handleGetAbbreviation(nickname || email || 'User')}
      </Avatar>
      {needName && nickname && <P1 weight="semibold">{nickname}</P1>}
    </div>
  )
}

UserAvatar.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fontSize: PropTypes.number,
  nickname: PropTypes.string,
  email: PropTypes.string,
  needName: PropTypes.bool,
  avatar: PropTypes.string
}

UserAvatar.defaultProps = {
  width: 45,
  height: 45,
  fontSize: 18,
  nickname: 'User',
  email: '',
  needName: false,
  avatar: ''
}

export default UserAvatar
