// All API urls are used in the project
export const API_CLOUDFUNCTIONS = process.env.NEXT_PUBLIC_API_URL
export const API_CONNECT_MONEYMADE = process.env.NEXT_PUBLIC_API_CONNECT_DOT_APP_URL
export const API_STRAPI_CMS = process.env.NEXT_PUBLIC_STRAPI_CMS_URL
export const API_DEALS = process.env.NEXT_PUBLIC_API_DEALS_URL
export const API_FEED = process.env.NEXT_PUBLIC_API_FEED_URL
export const API_DASHBOARD = process.env.NEXT_PUBLIC_DASHBOARD_URL
export const API_INDUSTRIES = process.env.NEXT_PUBLIC_MONEYMADE_INDUSTRIES_URL
export const API_WATCHLIST = process.env.NEXT_PUBLIC_WATCHLIST_URL
export const API_ONE_DATA = process.env.NEXT_PUBLIC_ONE_DATA_API

export const STORAGE_URL = 'https://storage.googleapis.com/benchmark-media.appspot.com/uploads'
export const THUMBNAILS_URL = 'https://storage.googleapis.com/benchmark-media.appspot.com/thumbnails'
export const MONEYMADE_CONNECT_URL = process.env.NEXT_PUBLIC_MONEYMADE_CONNECT_URL
export const MONEYMADE_CONNECTOR_URL = 'https://connect-widget-stage.vercel.app/moneymade-connect.js'

export const MONEYMADE_CONNECTOR_ENV = process.env.NEXT_PUBLIC_MONEYMADE_CONNECTOR_ENV

export const API_CONNECT_DOT_APP_ENGINE_URL = process.env.NEXT_PUBLIC_API_CONNECT_DOT_APP_ENGINE_URL
export const API_DEALS_URL = process.env.NEXT_PUBLIC_API_DEALS_URL
export const API_FEED_URL = process.env.NEXT_PUBLIC_API_FEED_URL
export const ENFORMED_URL = process.env.NEXT_PUBLIC_ENFORMED_URL
export const API_PERSON_URL = process.env.NEXT_PUBLIC_API_PERSON_URL
