import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setFooterFieldAction } from 'redux/app/actions'
import Footer from './Footer'

const FooterContainer = props => {
  const { isLogged } = useSelector(({ auth }) => auth)

  const {
    footer: { isAdvertiserModal, discover, articles, collection, trending, reviews }
  } = useSelector(({ app }) => app)

  const dispatch = useDispatch()

  const setFooterField = useCallback(payload => dispatch(setFooterFieldAction(payload)), [dispatch])

  const updatedProps = {
    ...props,
    isLogged,
    isAdvertiserModal,
    discover,
    articles,
    collection,
    trending,
    reviews,
    handleSetFooterField: setFooterField
  }

  return <Footer {...updatedProps} />
}

export default FooterContainer
