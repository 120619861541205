import { SCHEMA } from 'modules/home/HomePage/homePageConstants'
import Head from 'next/head'
import PropTypes from 'prop-types'

import { removeHTMLTags } from 'utils'

const HeadTags = ({
  title,
  description,
  schema: { logo, name, totalReviews, averageRating } = {},
  thumbnail,
  softDelete,
  schemaArticle: { headline, image, datePublished, dateModified } = {},
  moneyChat
}) => {
  const currentPath = typeof window === 'object' ? `${window.location.origin}${window.location.pathname}` : ''
  const headTitle = title.includes('MoneyMade') ? title : `${title} - MoneyMade`
  const thumbnailUrl = thumbnail || 'https://assets.moneymade.io/images/app/MoneyMade_MetaImage_MO.png'

  // Schema for platform review
  const schema = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name,
    image: [logo],
    description: removeHTMLTags(description),
    url: currentPath,
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: averageRating,
      reviewCount: totalReviews
    }
  }

  // Schema for article
  const schemaArticle = {
    '@context': 'https://schema.org',
    '@type': 'Article',
    headline,
    image: [image || 'https://assets.moneymade.io/images/app/MoneyMade_MetaImage_MO.png'],
    datePublished,
    dateModified
  }

  return (
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />

      {title && <title key="title">{headTitle}</title>}
      {description && (
        <meta key="description" property="description" name="description" content={removeHTMLTags(description)} />
      )}

      {/* Facebook Meta Tags */}
      <meta key={`ogurl-${currentPath}`} property="og:url" content={currentPath} />
      {title && <meta key="og:site_name" property="og:site_name" content={headTitle} />}
      {title && <meta key="og:title" property="og:title" content={headTitle} />}
      {description && <meta key="og:description" property="og:description" content={removeHTMLTags(description)} />}
      <meta key="og:image" property="og:image" content={thumbnailUrl} />
      <meta key="og:type" property="og:type" content="article" />
      <meta key="fb:app_id" property="fb:app_id" content="778726669536004" />

      {/* Twitter Meta Tags */}
      <meta key={`twitter-${currentPath}`} property="twitter:url" content={currentPath} />
      <meta key="twitter:card" property="twitter:card" name="twitter:card" content="summary_large_image" />
      {title && <meta key="twitter:site" property="twitter:site" content={headTitle} />}
      {title && <meta key="twitter:title" property="twitter:title" content={headTitle} />}
      {description && (
        <meta key="twitter:description" property="twitter:description" content={removeHTMLTags(description)} />
      )}
      <meta key="twitter:image" property="twitter:image" content={thumbnailUrl} />

      <link key={`canonical-${currentPath}`} rel="canonical" href={currentPath} />

      <script async src="//www.instagram.com/embed.js" type="text/javascript" />

      <script async defer src="https://a.mmin.io/m/v3.min.js" />

      {name && totalReviews && (
        <script
          key="application/ld+json"
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
        />
      )}

      {title === 'Advertise' && (
        <script
          dangerouslySetInnerHTML={{
            __html: `!function () {var reb2b = window.reb2b = window.reb2b || [];if (reb2b.invoked) return;reb2b.invoked = true;reb2b.methods = ["identify", "collect"];reb2b.factory = function (method) {return function () {var args = Array.prototype.slice.call(arguments);args.unshift(method);reb2b.push(args);return reb2b;};};for (var i = 0; i < reb2b.methods.length; i++) {var key = reb2b.methods[i];reb2b[key] = reb2b.factory(key);}reb2b.load = function (key) {var script = document.createElement("script");script.type = "text/javascript";script.async = true;script.src = "https://s3-us-west-2.amazonaws.com/b2bjsstore/b/" + key + "/reb2b.js.gz";var first = document.getElementsByTagName("script")[0];first.parentNode.insertBefore(script, first);};reb2b.SNIPPET_VERSION = "1.0.1";reb2b.load("1N5W0H0LDXO5");}();`
          }}
        />
      )}

      {headline && image && datePublished && dateModified && (
        <script
          key="application/ld+json"
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaArticle) }}
        />
      )}

      {moneyChat && (
        <script
          key="application/ld+json"
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(SCHEMA) }}
        />
      )}

      {softDelete && <meta name="robots" content="noindex" />}
    </Head>
  )
}

HeadTags.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  schema: PropTypes.shape({
    logo: PropTypes.string,
    name: PropTypes.string,
    totalReviews: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    averageRating: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  schemaArticle: PropTypes.shape({
    headline: PropTypes.string,
    image: PropTypes.string,
    datePublished: PropTypes.string,
    dateModified: PropTypes.string
  }),
  thumbnail: PropTypes.string,
  softDelete: PropTypes.bool,
  moneyChat: PropTypes.bool
}

HeadTags.defaultProps = {
  title: '',
  description: '',
  schema: {
    logo: '',
    name: '',
    totalReviews: 0,
    averageRating: 0
  },
  schemaArticle: {
    headline: '',
    image: '',
    datePublished: '',
    dateModified: ''
  },
  thumbnail: '',
  softDelete: false,
  moneyChat: false
}

export default HeadTags
