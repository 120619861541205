import { Icon, P2, P3 } from '@moneymade/moneymade-ui'
import PropTypes from 'prop-types'
import { useMemo, useEffect, useRef } from 'react'

import styles from './ResultInputModal.module.scss'

const ResultInputModal = ({ handleClose, resultinput, inputRef }) => {
  const { asset, discover, learn } = useMemo(() => {
    const assets = resultinput?.filter(({ values }) => values?.dir1 === 'asset').slice(0, 3) || []
    const discovers = resultinput?.filter(({ values }) => values?.dir1 === 'discover').slice(0, 3) || []
    const learns = resultinput?.filter(({ values }) => values?.dir1 === 'learn').slice(0, 3) || []

    return {
      asset: assets,
      discover: discovers,
      learn: learns
    }
  }, [resultinput])

  const elRef = useRef(null)

  useEffect(() => {
    const elRefClick = event => {
      const { target } = event
      const { current } = elRef
      const { current: currentInput } = inputRef

      if (!current || !currentInput || !target) return

      if (!current.contains(target)) {
        if (!currentInput.contains(target)) {
          handleClose(event)
        }
      }
    }

    // Add when mounted
    document.addEventListener('mousedown', elRefClick)
    // Return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', elRefClick)
    }
  }, [handleClose, inputRef])

  const handleSliceLongText = text => (text?.length > 50 ? `${text.slice(0, 46)}...` : text)

  return (
    <div className={styles.Modal} ref={elRef}>
      {resultinput.length ? (
        <div className={styles.List}>
          {asset.length ? (
            <div className={styles.ElementOfList}>
              <P3 weight="normal" className={styles.Title}>
                Assets <Icon icon="icon-point-right" size={12} />
              </P3>

              {asset.map(({ values: { title, url, image, id } }) => (
                <a href={url} key={id} rel="noreferrer" className={styles.Link}>
                  <img src={image} alt={title.replace(' - MoneyMade', '')} />
                  <P2 weight="normal">{title.replace(' - MoneyMade', '')}</P2>
                </a>
              ))}
            </div>
          ) : null}

          {discover.length ? (
            <div className={styles.ElementOfList}>
              <P3 weight="normal" className={styles.Title}>
                Investment Platforms <Icon icon="icon-point-right" size={12} />
              </P3>

              {discover.map(({ values: { title, url, image, id } }) => (
                <a href={url} key={id} rel="noreferrer" className={styles.Link}>
                  <img src={image} alt={title.replace(' - MoneyMade', '')} />
                  <P2 weight="normal">{title.replace(' - MoneyMade', '')}</P2>
                </a>
              ))}
            </div>
          ) : null}

          {learn.length ? (
            <div className={styles.ElementOfList}>
              <P3 weight="normal" className={styles.Title}>
                Reads <Icon icon="icon-point-right" size={12} />
              </P3>
              {learn.map(({ values: { title, url, image, id } }) => (
                <a href={url} key={id} rel="noreferrer" className={`${styles.Link} ${styles.Learn}`}>
                  <img src={image} alt={title.replace(' - MoneyMade', '')} />
                  <P2 weight="normal">{handleSliceLongText(title.replace(' - MoneyMade', ''))}</P2>
                </a>
              ))}
            </div>
          ) : null}
        </div>
      ) : (
        <div className={styles.NotFound}>
          <P3>
            No results found <Icon icon="icon-point-right" size={12} />
          </P3>
        </div>
      )}
    </div>
  )
}

ResultInputModal.propTypes = {
  resultinput: PropTypes.arrayOf(PropTypes.object),
  inputRef: PropTypes.shape({ current: PropTypes.string }),
  handleClose: PropTypes.func
}

ResultInputModal.defaultProps = {
  resultinput: [],
  inputRef: {},
  handleClose: () => {}
}
export default ResultInputModal
