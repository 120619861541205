import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { setUserFieldsAction } from 'redux/auth/actions'
import MobHeader from './MobHeader'

const MobHeaderContainer = props => {
  const dispatch = useDispatch()

  const setUserFields = useCallback(payload => dispatch(setUserFieldsAction(payload)), [dispatch])

  const updatedProps = {
    ...props,
    handleSetUserFields: setUserFields
  }

  return <MobHeader {...updatedProps} />
}

export default MobHeaderContainer
