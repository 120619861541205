import { useSelector } from 'react-redux'

import MainLayout from './MainLayout'

const MainLayoutContainer = props => {
  const { inIframe } = useSelector(({ app }) => app)

  const updatedProps = {
    ...props,
    inIframe
  }

  return <MainLayout {...updatedProps} />
}

export default MainLayoutContainer
