import PropTypes from 'prop-types'
import { P3 } from '@moneymade/moneymade-ui'

import styles from './NotFound.module.scss'

const NotFound = ({ text, size }) => (
  <P3 weight="light" className={styles.NotFound} style={{ fontSize: `${size}px` }}>
    {text}
  </P3>
)

NotFound.propTypes = {
  text: PropTypes.string,
  size: PropTypes.number
}

NotFound.defaultProps = {
  text: 'Not Found',
  size: 32
}

export default NotFound
