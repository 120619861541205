import { SET_USER_FIELD, SET_USER_FIELDS, SET_USER_DATA_FIELD, SET_USER_DATA_FIELDS, LOGOUT_USER } from './type'

const setUserFieldAction = payload => ({
  type: SET_USER_FIELD,
  payload
})

const setUserFieldsAction = payload => ({
  type: SET_USER_FIELDS,
  payload
})
const setUserDataFieldAction = payload => ({
  type: SET_USER_DATA_FIELD,
  payload
})

const setUserDataFieldsAction = payload => ({
  type: SET_USER_DATA_FIELDS,
  payload
})

const logoutUserAction = () => ({
  type: LOGOUT_USER
})

export { setUserFieldAction, setUserFieldsAction, setUserDataFieldAction, setUserDataFieldsAction, logoutUserAction }
