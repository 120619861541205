import { useState, useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import PropTypes from 'prop-types'

import { isBrowser } from 'utils'

const ObserverSensor = ({ id, onMouseOver: onMouseOverProp, onScroll: onScrollProp, children }) => {
  const [hovered, setHovered] = useState(false)
  const [scrolled, setScrolled] = useState(false)

  const element = isBrowser() && document.getElementById(id)

  const handleScroll = () => {
    if (element && !scrolled && isMobile) {
      const windowPosition = window.scrollY
      const elementPosition = element.getBoundingClientRect().bottom

      if (windowPosition > elementPosition && !scrolled) {
        setScrolled(true)
        onScrollProp()
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return window.removeEventListener('scroll', handleScroll)
  })

  const handleMouseOver = ({ currentTarget }) => {
    if (currentTarget.id === id && !hovered) {
      setHovered(true)
      onMouseOverProp()
    }
  }

  return (
    <div id={id} onFocus={handleMouseOver} onMouseOver={handleMouseOver}>
      {children}
    </div>
  )
}

ObserverSensor.propTypes = {
  id: PropTypes.string,
  onMouseOver: PropTypes.func,
  onScroll: PropTypes.func,
  children: PropTypes.node
}

ObserverSensor.defaultProps = {
  id: '',
  onMouseOver: () => {},
  onScroll: () => {},
  children: null
}

export default ObserverSensor
