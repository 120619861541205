import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { setLSData, removeLSData, getExpirationDate } from 'utils'
import { logoutUserAction, setUserFieldsAction, setUserDataFieldsAction } from 'redux/auth/actions'
import { getWatchlistCall } from 'api/calls'

import { resetHubAction } from 'redux/hub/actions'

const useAuth = () => {
  const dispatch = useDispatch()
  // Redux actions
  const setUserFields = useCallback(payload => dispatch(setUserFieldsAction(payload)), [dispatch])
  const setUserDataFields = useCallback(payload => dispatch(setUserDataFieldsAction(payload)), [dispatch])

  const logoutUser = useCallback(() => dispatch(logoutUserAction()), [dispatch])
  const resetHub = useCallback(() => dispatch(resetHubAction()), [dispatch])

  // Log in
  const handleSignIn = useCallback(
    async (user = {}) => {
      const { uid, displayName, photoURL, email, metadata: { creationTime } = {} } = user

      // Get token from firebase user
      const token = await user.getIdToken()
      // Get expiration date of token
      const expirationDate = getExpirationDate(token)

      // Get user Watchlist
      const { success: successList, result: resultList } = getWatchlistCall(token)()
      // Set user data to redux store
      setUserFields([
        { key: 'isAuthModal', value: false },
        { key: 'isAuthChecked', value: true },
        { key: 'isLogged', value: true }
      ])

      setUserDataFields([
        { key: 'userId', value: uid },
        { key: 'email', value: email },
        { key: 'nickname', value: displayName },
        { key: 'token', value: token },
        { key: 'avatar', value: photoURL || 'https://assets.moneymade.io/images/profile/1.png' },
        { key: 'expirationDate', value: expirationDate },
        { key: 'registeredDate', value: creationTime },
        { key: 'watchList', value: successList ? resultList : [] }
      ])
      // Set user id to LS
      setLSData('userId', uid)
    },
    [setUserFields, setUserDataFields]
  )

  // Log out
  const handleSignOut = useCallback(() => {
    // Reset user data
    logoutUser()
    // Reset hub
    resetHub()
    // Remove user id from LS
    removeLSData('userId')
  }, [logoutUser, resetHub])

  return { handleSignIn, handleSignOut }
}

export default useAuth
