import { OBJECTIVES } from 'modules/discover/discoverConstants'
import { toNumberWithDecimalsAfterPoint } from 'utils/convertors'
import { toMoneyFormat } from 'utils/helpers'

const getPaidInvestments = investments => (investments ? investments.filter(({ paid }) => paid) : [])

export const getNoPaidInvestments = investments => (investments ? investments.filter(({ paid }) => !paid) : [])

export const getPremiumInvestments = investments => {
  if (investments) {
    const mmPremiumInvestments = investments.filter(({ mmPremium }) => mmPremium)
    const preminum = investments.filter(({ premium }) => premium > 0)

    const tmp = new Map()
    const withoutDuplicate = [...mmPremiumInvestments, ...preminum].reduce((acc, e) => {
      if (tmp.has(e.slug)) {
        if (!tmp.get(e.slug).includes(e.jobid)) {
          acc.push(e)
          tmp.set(e.slug, [...tmp.get(e.slug), e.jobid])
        }
      } else {
        acc.push(e)
        tmp.set(e.slug, [e.jobid])
      }
      return acc
    }, [])

    if (withoutDuplicate?.length >= 2) {
      return withoutDuplicate
    }

    const randomPlatforms = investments.reduce(acc => {
      if (acc.length < 3) {
        const randomIndex = Math.floor(Math.random() * investments.length)
        if (!acc.includes(investments[randomIndex])) {
          acc.push(investments[randomIndex])
        }
      }
      return acc
    }, [])

    return [...mmPremiumInvestments, ...preminum, ...randomPlatforms]
  }
}

// Convert to number with max two decimal numbers
export const toNumberWithDecimal = value => +Number(value).toFixed(2)

// Split number to 10,000,000.3167916
export const toWithSeparator = string => {
  const isStringContainsComa = String(string).includes('.')
  const [beforeComa, afterComa] = String(string).split('.')
  const beforeComaWithSeparator = beforeComa.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')

  return `${beforeComaWithSeparator}${isStringContainsComa ? '.' : ''}${afterComa || ''}`
}

const getPassiveIncome = investmentsData =>
  investmentsData ? investmentsData.filter(({ objectives }) => objectives.includes('Passive Income')) : []

const getBalanced = investmentsData =>
  investmentsData ? investmentsData.filter(({ objectives }) => objectives.includes('Balanced Investing')) : []

const getLongTermGrowth = investmentsData =>
  investmentsData ? investmentsData.filter(({ objectives }) => objectives.includes('Long Term Growth')) : []

export const getFilteredInvestments = (investments, filters) =>
  filters.reduce((accumulator, { name, item, multiple }) => {
    switch (name) {
      case 'availability': {
        let filteredByAvailability = accumulator

        if (item.includes('Accredited Only') && item.includes('Non-Accredited Investors')) {
          filteredByAvailability = investments
        } else {
          if (item.includes('Non-Accredited Investors'))
            filteredByAvailability = filteredByAvailability.filter(({ investors }) => investors === 'All')
          if (item.includes('Accredited Only'))
            filteredByAvailability = filteredByAvailability.filter(({ investors }) => investors === 'Accredited')
        }

        if (item.includes('Outside US')) filteredByAvailability = filteredByAvailability.filter(({ global }) => global)

        return filteredByAvailability
      }

      case 'industry': {
        return accumulator.filter(({ industries }) => industries.some(industry => item.includes(industry?.name)))
      }

      case 'minInv': {
        return item.length && Number.isInteger(+item)
          ? accumulator.filter(({ minInvestment }) => +item <= minInvestment)
          : accumulator
      }

      case 'maxInv': {
        return item.length && Number.isInteger(+item)
          ? accumulator.filter(({ minInvestment }) => +item >= minInvestment)
          : accumulator
      }

      case 'newRisks': {
        return accumulator.filter(({ newRisks }) => {
          if (multiple) {
            const currentValue = item.map(risk =>
              risk === 'Low risk' ? '1' : risk === 'Medium risk' ? '2' : risk === 'High risk' ? '3' : ''
            )
            return currentValue.includes(newRisks[0])
          }
          const currentValue =
            item === 'Low risk' ? '1' : item === 'Medium risk' ? '2' : item === 'High risk' ? '3' : ''
          return newRisks[0] === currentValue
        })
      }

      default:
        return accumulator.filter(investment => {
          if (Array.isArray(investment[name])) {
            return multiple ? investment[name].some(value => item.includes(value)) : investment[name].includes(item)
          }
          return multiple ? item.includes(investment[name]) : investment[name] === item
        })
    }
  }, investments)

export const getObjectives = investments => {
  const { passiveIncome, balancedInvesting, longTermWorth } = OBJECTIVES

  const investmentsData = getPaidInvestments(investments)

  passiveIncome.platforms = getPassiveIncome(investmentsData)
  balancedInvesting.platforms = getBalanced(investmentsData)
  longTermWorth.platforms = getLongTermGrowth(investmentsData)

  return [passiveIncome, balancedInvesting, longTermWorth]
}

export const getFilterQuiz = (data, riskValue, cashValue, timeframeValue, accreditedValue) => {
  let filterredList = data

  // Risk
  filterredList = riskValue?.length
    ? riskValue.reduce((acc, value) => [...acc, ...filterredList.filter(({ newRisks }) => newRisks[0] === value)], [])
    : filterredList

  // Cash Payments
  filterredList = cashValue?.length
    ? cashValue.reduce((acc, value) => {
        switch (value) {
          case '1':
            return [...acc, ...filterredList.filter(({ objectives }) => objectives.includes('Passive Income'))]

          case '2':
            return [...acc, ...filterredList.filter(({ objectives }) => objectives.includes('Long Term Growth'))]

          case '3':
            return [...acc, ...filterredList.filter(({ objectives }) => objectives.includes('Balanced Investing'))]

          default:
            return acc
        }
      }, [])
    : filterredList

  // Timeframe
  filterredList = timeframeValue?.length
    ? timeframeValue.reduce(
        (acc, value) => [...acc, ...filterredList.filter(({ timeframeNumber }) => timeframeNumber <= Number(value))],
        []
      )
    : filterredList

  // Accredited
  if (!accreditedValue.includes('1')) filterredList = filterredList.filter(({ investors }) => investors === 'All')

  // Remove dublicates from array with objects
  filterredList = [...new Map(filterredList.map(inv => [inv.id, inv])).values()]
  return filterredList
}

// @Ruslan - used only in one place
export const getAverageValue = (data, value) =>
  Object.values(data).reduce((acc, currentValue) => acc + currentValue[value], 0) / Object.values(data).length || 0

// @Ruslan - I belive we can replace it with existing functions or move somewhere. Let me know
export const getDecimalAdjustmentOfNumber = (type, value, exp) => {
  // If the exp is undefined or zero...
  if (typeof exp === 'undefined' || +exp === 0) Math[type](value)

  value = +value
  exp = +exp

  // If the value is not a number or the exp is not an integer...
  if (Number.isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) return NaN

  // Shift
  value = value.toString().split('e')
  value = Math[type](+`${value[0]}e${value[1] ? +value[1] - exp : -exp}`)

  // Shift back
  value = value.toString().split('e')

  return +`${value[0]}e${value[1] ? +value[1] + exp : exp}`
}

// @Ruslan - I think we can avoid using this function or move to individual file
export const getLastValueFromArray = (ar, partition = 'balance', currentValue = null) => {
  const dataFiltered = Object.values(ar).filter(i => i.balances !== null)
  return !currentValue ? dataFiltered[dataFiltered.length - 1][partition] : currentValue
}

export const getAccountValue = (totalData, accountValue) =>
  `$${toNumberWithDecimalsAfterPoint(
    toMoneyFormat(getDecimalAdjustmentOfNumber('round', getLastValueFromArray(totalData, 'balance', accountValue), -2))
  )}`

export const getTokenJwt = token => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  )

  return JSON.parse(jsonPayload)
}

/**
 * convert unix time to date time
 * @param {string} token
 */
export const getExpirationDate = token => {
  // get JWT encoded token
  const jwt = getTokenJwt(token)
  // convert unix time to date time
  return jwt?.exp ? jwt.exp * 1000 : null
}

export const getWaysInvest = (investments, industries) => {
  const paidPlatforms = getPaidInvestments(investments)

  const platformsIndustries =
    paidPlatforms && industries
      ? paidPlatforms.filter(({ industries: industriesAll }) =>
          industriesAll.some(({ name: namePlatform }) => industries[0]?.name === namePlatform)
        )
      : []

  const platformsOtherIndustries = investments
    ? investments.filter(({ industry, paid }) => industry === industries[0]?.name && !paid)
    : []

  return [...platformsIndustries, ...platformsOtherIndustries]
}

// @Ruslan - used only in one file. Not sure we have to have this function
const getDiffBetweenLastAndFirstVals = (data, partition, currentValue = null, dataByFilter) => {
  if (currentValue) return currentValue - data[0][partition]
  if (dataByFilter) return dataByFilter - data[0][partition] || data[0][partition]
  return data[data.length - 1][partition] - data[0][partition]
}
export const getCdnImg = (url, width = 500, scale = false, quality = 100) => {
  const urlSource = 'https://static.moneymade.io/cdn-cgi/image'
  if (scale) {
    // return `${urlSource}/width=${width * 2},quality=${quality},format=auto/${url} 2x`
    return [1, 2, 3].map(
      (_, key) => `${urlSource}/width=${width * (key + 1)},quality=${quality},format=auto/${url} ${key + 1}x,`
    )
  }
  return `${urlSource}/width=${width},quality=${quality},format=auto/${url}`
}

// @Ruslan - used only in one place
const getPercentage = (data, partition, currentValue = null, dataByFilter) => {
  const percentAge =
    (getDiffBetweenLastAndFirstVals(data, partition, currentValue, dataByFilter) / data[data.length - 1][partition]) *
    100
  return Number.isFinite(percentAge) ? percentAge : 0
}

export const getImgs = (imgs, width) => imgs?.map(img => getCdnImg(img, width))

const getNumberSign = value => {
  if (value < 0) return '-'
  if (value > 0) return '+'
  return ''
}
// @Ruslan - not readable function. Rework and move. Used only in 2 places
export const getDifferenceDataCombined = (value, partition, currentValue = null, dataByFilter) =>
  `${getNumberSign(
    getDiffBetweenLastAndFirstVals(value, partition, currentValue, dataByFilter)
  )}$${getDecimalAdjustmentOfNumber(
    'round',
    Math.abs(getDiffBetweenLastAndFirstVals(value, partition, currentValue, dataByFilter)),
    -2
  )} (${
    value[0][partition] === 0
      ? `100%)`
      : `${getDecimalAdjustmentOfNumber(
          'round',
          Math.abs(getPercentage(value, partition, currentValue, dataByFilter)) || 0,
          -2
        )}%)`
  }`
