import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import useOnScreen from 'hooks/useOnScreen'

const Observer = ({ as, visiblePercent, visibilityMargin, onVisible, onOver, onScrollThrough, children, ...props }) => {
  const ref = useRef(null)
  const inViewport = useOnScreen(ref, visibilityMargin || '0px', visiblePercent || 0.5)

  const [isScrolledThrough, setScrolledThrough] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current && !isScrolledThrough) {
        const windowPosition = window.scrollY
        const elementPosition = ref.current.getBoundingClientRect().bottom

        if (windowPosition > elementPosition) {
          setScrolledThrough(true)

          if (onScrollThrough) {
            onScrollThrough(true)
          }
        }
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  useEffect(() => {
    if (onVisible) {
      onVisible(inViewport)
    }
  }, [inViewport, onVisible])

  const handleMouseOver = status => () => {
    if (onOver) {
      onOver(status)
    }
  }

  return React.createElement(
    as || 'div',
    {
      ref,
      onMouseEnter: handleMouseOver(true),
      onMouseLeave: handleMouseOver(false),
      onTouchStart: handleMouseOver(true),
      onTouchEnd: handleMouseOver(false),
      ...props
    },
    children
  )
}

Observer.propTypes = {
  as: PropTypes.string,
  visiblePercent: PropTypes.number,
  visibilityMargin: PropTypes.string,
  onVisible: PropTypes.func,
  onOver: PropTypes.func,
  onScrollThrough: PropTypes.func,
  children: PropTypes.node
}

Observer.defaultProps = {
  as: 'div',
  visiblePercent: 0.5,
  visibilityMargin: '',
  onVisible: () => {},
  onOver: () => {},
  onScrollThrough: () => {},
  children: null
}

export default Observer
