import { call } from 'api/callConstructor'
import {
  API_INDUSTRIES,
  API_STRAPI_CMS,
  API_WATCHLIST,
  API_CLOUDFUNCTIONS,
  API_DEALS,
  API_ONE_DATA,
  STORAGE_URL,
  API_CONNECT_MONEYMADE,
  API_CONNECT_DOT_APP_ENGINE_URL,
  API_DASHBOARD
} from 'api/apiConstants'

export const getAssetPagesCall = () => {
  const url = `${API_STRAPI_CMS}/asset-pages?publishedStatus=published`
  return call({ method: 'get', url })
}

export const getTimeSeriesV2Call = params => {
  const url = `${API_ONE_DATA}/api/v2/data/market-data/time-series?${params}`
  return call({ method: 'get', url })
}

export const getAssetPageCall = slug => {
  const url = `${API_STRAPI_CMS}/asset-pages/${slug}`
  return call({ method: 'get', url })
}

export const getAvgReturnCall = slug => {
  const url = `${API_INDUSTRIES}/industry/average-change/${slug}`
  return call({ method: 'get', url })
}

export const getAvgReturAllnCall = paramsAll => Promise.all(paramsAll.map(async params => getAvgReturnCall(params)()))

export const getWatchlistElement = symbol => {
  const url = `${API_WATCHLIST}/api/v1/watchlist/get-data/${symbol}`
  return call({ method: 'get', url })
}

export const getAssetsRiskGraphCall = params => {
  const url = `https://tlbu9jn7o3.execute-api.us-east-2.amazonaws.com/stage?${params}`
  return call({ method: 'get', url })
}

export const getCalcDataCall = () => {
  const url = 'https://80otmjjn55.execute-api.us-east-2.amazonaws.com/stage_v2'
  return call({ method: 'get', url })
}

export const getAssetsTopNftsCall = () => {
  const url = `${API_INDUSTRIES}/industry/nft/top`
  return call({ method: 'get', url })
}

export const getAssetsNftDailyVolumeCall = () => {
  const url = `${API_INDUSTRIES}/industry/nft/graph/volume`
  return call({ method: 'get', url })
}

export const getAssetsCompareTopCall = () => {
  const url = `${API_INDUSTRIES}/industry/compare/top`
  return call({ method: 'get', url })
}

export const getAssetsCompareTopOneSlugsYearCall = () => {
  const slugs = 'pick-fields=oneYear[*].change&pick-fields=oneYear[*].slug&pick-fields=oneYear[*].name'
  const url = `${API_INDUSTRIES}/industry/compare/top?${slugs}`
  return call({ method: 'get', url })
}

export const getAssetsCompareTopOneYearCall = () => {
  const url = `${API_INDUSTRIES}/industry/compare/top?pick-fields=oneYear`
  return call({ method: 'get', url })
}

export const getAssetsCompareManyByParamsCall = params => {
  const url = `${API_INDUSTRIES}/industry/compare/many?${params}`
  return call({ method: 'get', url })
}

export const getSectorsCall = params => {
  const url = `${API_INDUSTRIES}/industry/info?${params}`
  return call({ method: 'get', url })
}

export const getIndustryCall = params => {
  const url = `${API_INDUSTRIES}/industry/info/${params}`
  return call({ method: 'get', url })
}

export const getWorthNetCall = (idsParam, params) => {
  const url = `https://mm-one-data-api-dot-moneyman-ssr.uc.r.appspot.com/api/v2/data/market-data/time-series?${idsParam}${
    params ? `&${params}` : ''
  }`
  return call({ method: 'get', url })
}

export const getCompareIndustriesCall = params => {
  const url = `${API_INDUSTRIES}/industry/compare/growth?${params}`
  return call({ method: 'get', url })
}

export const getAssetsCompareManyCall = params => {
  const url = `${API_INDUSTRIES}/industry/compare/many?${params}`
  return call({ method: 'get', url })
}

export const getAskWidgetAssetsCall = () => {
  const url = `${API_CLOUDFUNCTIONS}/getAskWidgetAssets`
  return call({ method: 'get', url })
}

export const getAvgChangesReturnCall = slugs => {
  const url = `${API_INDUSTRIES}/industry/average-changes?${slugs}`
  return call({ method: 'get', url })
}

export const getDealsByPlatformCall = params => {
  const url = `${API_DEALS}/deal/top?${params}`
  return call({ method: 'get', url })
}

export const getHomeCall = () => {
  const url = `${API_STRAPI_CMS}/home`
  return call({ method: 'get', url })
}

export const getIndustriesCall = (slug = '') => {
  const url = `${API_STRAPI_CMS}/industries${slug ? `/${slug}` : ''}`
  return call({ method: 'get', url })
}

export const getIndustriesAllCall = () => {
  const url = `${API_STRAPI_CMS}/industries`
  return call({ method: 'get', url })
}

export const getMoneyChatCall = () => {
  const url = `${API_STRAPI_CMS}/chat`
  return call({ method: 'get', url })
}

export const getAssetTopCall = () => {
  const url = `${API_INDUSTRIES}/industry/top`
  return call({ method: 'get', url })
}

export const getGainersLosersCall = () => {
  const url = `${API_INDUSTRIES}/industry/gainers-and-losers`
  return call({ method: 'get', url })
}

export const getIndustryInfoCall = slug => {
  const url = `${API_INDUSTRIES}/industry/info/${slug}`
  return call({ method: 'get', url })
}

export const getDiscoverSidebarCall = () => {
  const url = `${API_STRAPI_CMS}/discoveries`
  return call({ method: 'get', url })
}

export const getInvestmentsCall = (fields = '') => {
  const url = `${API_STRAPI_CMS}/entries${fields}`
  return call({ method: 'get', url })
}

export const getCollectionsCall = (fields = '') => {
  const url = `${API_STRAPI_CMS}/collections?_sort=created_at:DESC&publishedStatus=published${fields}`
  return call({ method: 'get', url })
}

export const getCollectionCall = slug => {
  const url = `${API_STRAPI_CMS}/collections/${slug}`
  return call({ method: 'get', url })
}

export const getCollectionAllCall = () => {
  const url = `${API_STRAPI_CMS}/collections`
  return call({ method: 'get', url })
}

export const getBannersCall = () => {
  const url = `${API_STRAPI_CMS}/banners`
  return call({ method: 'get', url })
}

export const getMyMoneyCall = () => {
  const url = `${API_STRAPI_CMS}/my-money`
  return call({ method: 'get', url })
}

export const getShareImageCall = slug => {
  const url = `${STORAGE_URL}/${slug}.png`
  return call({ method: 'get', url })
}

export const getCtaCall = slug => {
  const url = `${API_STRAPI_CMS}/ctas/${slug}`
  return call({ method: 'get', url })
}

export const getAssetsTagsCall = () => {
  const url = `${API_STRAPI_CMS}/asset-tags`
  return call({ method: 'get', url })
}

export const getAboutUsCall = () => {
  const url = `${API_STRAPI_CMS}/about-us`
  return call({ method: 'get', url })
}

export const getFeedCall = (queryParams = 'page=1') => {
  const url = `${API_STRAPI_CMS}/parsed-contents?publishedStatus=published&${queryParams}`
  return call({ method: 'get', url })
}

export const getFeedPostCall = id => {
  const url = `${API_STRAPI_CMS}/parsed-contents/${id}`
  return call({ method: 'get', url })
}

export const getArticleCall = slug => {
  const url = `${API_STRAPI_CMS}/articles/${slug}`
  return call({ method: 'get', url })
}

export const getArticlesCall = (fields = '') => {
  const url = `${API_STRAPI_CMS}/articles?_sort=created_at:DESC&publishedStatus=published${fields}`
  return call({ method: 'get', url })
}

export const getSponsoredArticleCall = () => {
  const url = `${API_STRAPI_CMS}/articles?_sort=created_at:DESC&publishedStatus=published&sponsored=true`
  return call({ method: 'get', url })
}

export const getLimitedArticlesCall = limit => {
  const url = `${API_STRAPI_CMS}/articles?_sort=created_at:DESC&publishedStatus=published&${limit}`
  return call({ method: 'get', url })
}

export const getArticlesAllCall = () => {
  const url = `${API_STRAPI_CMS}/articles`
  return call({ method: 'get', url })
}

export const getVideoReviewsCall = () => {
  const url = `${API_STRAPI_CMS}/video-reviews?_sort=published_at:DESC`
  return call({ method: 'get', url })
}

export const getArticleQACall = slug => {
  const url = `${API_STRAPI_CMS}/q-and-as/${slug}`
  return call({ method: 'get', url })
}

export const getArticlesQACall = (fields = '') => {
  const url = `${API_STRAPI_CMS}/q-and-as?_sort=created_at:DESC&publishedStatus=published${fields}`
  return call({ method: 'get', url })
}

export const getArticlesQAAllCall = () => {
  const url = `${API_STRAPI_CMS}/q-and-as`
  return call({ method: 'get', url })
}

export const getPlatformReviewCall = slug => {
  const url = `${API_STRAPI_CMS}/platform-reviews/${slug}`
  return call({ method: 'get', url })
}

export const getPlatformReviewsCall = (fields = '') => {
  const url = `${API_STRAPI_CMS}/platform-reviews?_sort=created_at:DESC&publishedStatus=published${fields}`
  return call({ method: 'get', url })
}

export const getPlatformReviewsAllCall = () => {
  const url = `${API_STRAPI_CMS}/platform-reviews`
  return call({ method: 'get', url })
}

export const getFeaturedThemesCall = () => {
  const url = `${API_STRAPI_CMS}/featured-themes`
  return call({ method: 'get', url })
}

export const getAuthorsCall = () => {
  const url = `${API_STRAPI_CMS}/author-pages`
  return call({ method: 'get', url })
}

export const getAuthorCall = (slug, queryParams = 'page=1') => {
  const url = `${API_STRAPI_CMS}/author-pages/${slug}?${queryParams}`
  return call({ method: 'get', url })
}

export const getTimeSeriesCall = params => {
  const url = `${API_ONE_DATA}/api/v1/data/market-data/time-series?${params}`
  return call({ method: 'get', url })
}

export const getQuoteCall = params => {
  const url = `${API_ONE_DATA}/twelvedata/quote?${params}`
  return call({ method: 'get', url })
}

export const getStatisticsCall = params => {
  const url = `${API_ONE_DATA}/twelvedata/statistics?${params}`
  return call({ method: 'get', url })
}

export const getGainersLosersStocksCall = params => {
  const url = `${API_ONE_DATA}/api/v2/data/market-data/gainers-losers/stocks?${params}`
  return call({ method: 'get', url })
}

export const getGainersLosersCryptoCall = params => {
  const url = `${API_ONE_DATA}/api/v1/data/market-data/market-movers?${params}`
  return call({ method: 'get', url })
}

export const getPlaidLinkCall = token => {
  const url = `${API_CONNECT_DOT_APP_ENGINE_URL}/platforms/plaid/link-token`
  return call({ method: 'get', url, headers: { Authorization: `Bearer ${token}` } })
}

export const getPortfolioPlatformsCall = token => {
  const url = `${API_CONNECT_DOT_APP_ENGINE_URL}/platforms`
  return call({ method: 'get', url, headers: { Authorization: `Bearer ${token}` } })
}

export const getPortfolioPlatformsSearchedCall = (query, token) => {
  const url = `${API_CONNECT_DOT_APP_ENGINE_URL}/platforms?search=${query}&limit=100`
  return call({ method: 'get', url, headers: { Authorization: `Bearer ${token}` } })
}

export const getReviewedCall = token => {
  const url = `${API_CONNECT_DOT_APP_ENGINE_URL}/platforms/reviewed-info`
  return call({ method: 'get', url, headers: { Authorization: `Bearer ${token}` } })
}

export const getTransactionsCall = (token, platformId, userId) => {
  const url = `${API_CONNECT_DOT_APP_ENGINE_URL}/platforms/${platformId}/accounts/${userId}/transactions`
  return call({ method: 'get', url, headers: { Authorization: `Bearer ${token}` } })
}

export const getOffersApiCall = () => {
  const url = `https://rxwpp4tpca.us-east-1.awsapprunner.com/api/v1/offers?activeEq=true&statusEq=approved&typeEq=global`
  return call({
    method: 'get',
    url,
    headers: {
      Accept: 'application/json',
      'mm-x-authorization': 'bLuqFHrpg5CEr6QxsApt4CKttgFRDGKp39ahUePxQyC24sAWdRrQJGK5QS9wMA7S'
    }
  })
}

export const getAccountsCall = token => {
  const url = `${API_CONNECT_MONEYMADE}/v1/portfolio/accounts`
  return call({ method: 'get', url, headers: { Authorization: `Bearer ${token}` } })
}

export const getInHeaderArticleCall = () => {
  const url = `${API_STRAPI_CMS}/articles?publishedStatus=published&readInHeader=true`
  return call({ method: 'get', url })
}

export const getHeaderPlatformCall = () => {
  const url = `${API_STRAPI_CMS}/header-entries`
  return call({ method: 'get', url })
}

export const getPromosCall = () => {
  const url = `${API_CLOUDFUNCTIONS}/getPromos`
  return call({ method: 'get', url })
}

export const getManualAccountSchemasCall = token => {
  const url = `${API_CONNECT_MONEYMADE}/v1/portfolio/manual-account-schemas`
  return call({ method: 'get', url, headers: { Authorization: `Bearer ${token}` } })
}

export const getWatchlistCall = token => {
  const url = `${API_WATCHLIST}/api/v1/watchlist`
  return call({ method: 'get', url, headers: { Authorization: `Bearer ${token}` } })
}

export const searchWatchlistCall = (token, symbol) => {
  const url = `${API_WATCHLIST}/api/v1/watchlist/search-symbol/${symbol}`
  return call({ method: 'get', url, headers: { Authorization: `Bearer ${token}` } })
}

export const getPortfolioCall = token => {
  const url = `${API_DASHBOARD}/v1/portfolio`
  return call({ method: 'get', url, headers: { Authorization: `Bearer ${token}` } })
}

export const getPortfolioAnalysisCall = token => {
  const url = `${API_DASHBOARD}/v1/portfolio/analysis`
  return call({ method: 'get', url, headers: { Authorization: `Bearer ${token}` } })
}

export const getPortfolioRisksCall = token => {
  const url = `${API_DASHBOARD}/v1/portfolio/risks`
  return call({ method: 'get', url, headers: { Authorization: `Bearer ${token}` } })
}

export const getMoneymadeLinkCall = token => {
  const url = `${API_CONNECT_DOT_APP_ENGINE_URL}/platforms/moneymade-connect/link-token`
  return call({ method: 'get', url, headers: { Authorization: `Bearer ${token}` } })
}

export const getYodleeLinkCall = token => {
  const url = `${API_CONNECT_DOT_APP_ENGINE_URL}/platforms/yodlee/link-token`
  return call({ method: 'get', url, headers: { Authorization: `Bearer ${token}` } })
}

export const getOffersCall = () => {
  const url = `${API_STRAPI_CMS}/offers?sort=id:asc`
  return call({ method: 'get', url })
}

export const getPlatformCall = (slug, pickFileds = '') => {
  const url = `${API_STRAPI_CMS}/entries/${slug}${pickFileds}`
  return call({ method: 'get', url })
}

export const getInvestmentsAllCall = () => {
  const url = `${API_STRAPI_CMS}/entries?filters[$or][0][publishedStatus][$eq]=published&filters[$or][1][publishedStatus][$null]=null&filters[$or][2][publishedStatus][$eq]=draft`
  return call({ method: 'get', url })
}
