import { SET_URL_FIELD, SET_URL_FIELDS } from './types'

const initialState = {
  paramsAdded: false,
  itemCampaign: '',
  itemMedium: '',
  itemSource: '',
  itemContent: ''
}

const urlReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_URL_FIELD:
      return { ...state, [payload.key]: payload.value, paramsAdded: true }

    case SET_URL_FIELDS:
      return { ...state, ...payload.reduce((acc, { key, value }) => ({ ...acc, [key]: value }), {}), paramsAdded: true }

    default:
      return state
  }
}

export default urlReducer
