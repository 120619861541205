import PropTypes from 'prop-types'
import { Icon, useWindowSize } from '@moneymade/moneymade-ui'

import ImgLazy from 'components/ui/ImgLazy/ImgLazy'
import styles from './Logo.module.scss'

const DEFAULT_LOGO = 'https://assets.moneymade.io/images/app/MoneyMade%20Logo%20-%20Black.svg'
const DEFAULT_WHITE_DOT_LOGO = 'https://assets.moneymade.io/images/app/MoneyMade%20Logo%20-%20BlackWithWhiteDot.svg'
const LIGHT_LOGO = 'https://assets.moneymade.io/images/app/MoneyMade%20Logo%20-%20White.svg'

const Logo = ({ className, logoType, landing }) => {
  const { screenWidth } = useWindowSize()

  return !landing ? (
    <a href="https://moneymade.io/" className={`${styles.Logo} ${className} expertLogo`} rel="noreferrer">
      {screenWidth < 601 ? (
        <Icon icon="icon-mm-logo" size={24} />
      ) : (
        <ImgLazy
          src={logoType === 'darkLightDot' ? DEFAULT_WHITE_DOT_LOGO : logoType === 'light' ? LIGHT_LOGO : DEFAULT_LOGO}
          width="127"
          height="24"
          alt="logo"
        />
      )}
    </a>
  ) : (
    <div className={`${styles.Logo} ${className} expertLogo`}>
      {screenWidth < 601 ? (
        <Icon icon="icon-mm-logo" size={24} />
      ) : (
        <ImgLazy
          src={logoType === 'darkLightDot' ? DEFAULT_WHITE_DOT_LOGO : logoType === 'light' ? LIGHT_LOGO : DEFAULT_LOGO}
          alt="logo"
          width="127"
          height="24"
        />
      )}
    </div>
  )
}

Logo.propTypes = {
  className: PropTypes.string,
  landing: PropTypes.bool,
  logoType: PropTypes.oneOf(['dark', 'light', 'darkLightDot'])
}

Logo.defaultProps = {
  className: '',
  landing: false,
  logoType: 'dark'
}

export default Logo
