const SET_DISCOVER_FIELD = 'discover/SET_DISCOVER_FIELD'
const SET_DISCOVER_FIELDS = 'discover/SET_DISCOVER_FIELDS'
const SET_FILTER_OPTION = 'discover/SET_FILTER_OPTION'
const SET_FILTER_CONTAINS_OPTION = 'discover/SET_FILTER_CONTAINS_OPTION'
const RESET_ONE_FILTER = 'discover/RESET_ONE_FILTER'
const RESET_SIEVE = 'discover/RESET_SIEVE'

export {
  SET_DISCOVER_FIELD,
  SET_DISCOVER_FIELDS,
  SET_FILTER_OPTION,
  SET_FILTER_CONTAINS_OPTION,
  RESET_ONE_FILTER,
  RESET_SIEVE
}
