import ReactGA from 'react-ga'

import { isBrowser } from './helpers'

/**
 * Sends event to the Google Analytic
 * @param {string} category
 * @param {string*} action
 */
export const GASendEvent = (category, action) => ReactGA.event({ category: `${category}_website`, action })

/**
 * Tracks current page
 * @param {string} pathname Page pathname
 */
export const GAPageViewEvent = async pathname => ReactGA.pageview(pathname)

/**
 * Sends event to the Facebook Analytic
 * @param {string} category
 * @param {string} actionname
 * @param {string} action
 */
export const FBSendEvent = (category, actionName, action) => {
  if (isBrowser() && window.fbq) window.fbq('trackCustom', `${category}_website`, { [actionName]: action })
}

/**
 * Combines event sending to the Google Analytic and Facebook Analytic
 * @param {string} category
 * @param {string} actionname
 * @param {string} action
 */
export const GAFBSendEvent = (category, actionName, action) => {
  GASendEvent(category, action.toString())
  FBSendEvent(category, actionName, action)
}

/**
 * Combines event sending to the Google Analytic and Facebook Analytic
 * @param {string} category
 * @param {string} actions *
 */
export const FBSendEvents = (category, actions) => {
  if (window.fbq) {
    window.fbq('trackCustom', `${category}_website`, actions)
  }
}
