import { LazyLoadImage } from 'react-lazy-load-image-component'
import PropTypes from 'prop-types'

const ImgLazy = ({ effect, ...props }) => <LazyLoadImage effect={effect} alt="pic" {...props} />

ImgLazy.propTypes = {
  effect: PropTypes.string
}

ImgLazy.defaultProps = {
  effect: 'blur'
}

export default ImgLazy
