import { ROUTES } from 'static/menusConstants'

const MENU_FOOTER = [
  {
    title: 'About',
    menu: [
      { id: '0', label: 'Company', link: '/company' },
      { id: '1', label: 'Editorial guidelines', link: '/editorial-guidelines' },
      { id: '2', label: 'Editorial team', link: '/editorial-team' },
      { id: '3', label: 'Careers', link: `/careers` }
    ]
  },
  {
    title: 'Help',
    menu: [
      { id: '0', label: 'Contact us', link: '/contact-us' },
      { id: '1', label: 'Advertise', link: '/advertise' },
      { id: '2', label: 'Submit Product', link: `/${ROUTES.submitProduct}` }
    ]
  },
  {
    title: 'Legal',
    menu: [
      { id: '0', label: 'Terms of Use', link: `/${ROUTES.termsofUse}` },
      { id: '1', label: 'Supplier Code of Conduct', link: `/supplier-code-of-conduct` },
      { id: '2', label: 'Privacy Policy ', link: `/privacy-policy` },
      { id: '3', label: 'California privacy policy', link: `/california-privacy-policy` },
      { id: '4', label: 'Disclaimer', link: `/disclosure` }
    ]
  }
]
export default MENU_FOOTER
