import { useEffect } from 'react'
import { P1, P3, P4 } from '@moneymade/moneymade-ui'
import { useRouter } from 'next/router'
import Link from 'next/link'
import PropTypes from 'prop-types'
import moment from 'moment'

import ImgLazy from 'components/ui/ImgLazy/ImgLazy'
import { getCdnImg } from 'utils/getters'
import { HEADER_MENU, ROUTES } from 'static/menusConstants'
import styles from './HeaderMob.module.scss'

const HeaderMob = ({ investmentsHeader, readHeader, primaryColor, handleSetMobileMenu }) => {
  const { pathname, push } = useRouter()

  useEffect(() => {
    document.body.classList.add('modal-open')

    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  const handleIsActive = acceptedLinks =>
    acceptedLinks.some(link => (pathname.includes(link) && link !== '/') || pathname === link)

  const handleGoToArt = () => {
    handleSetMobileMenu(false)
    push('/discover/masterworks?utm_campaign=menu_button')
  }

  const closeMenu = () => {
    handleSetMobileMenu(false)
  }

  return (
    <header className={styles.HeaderMob}>
      {HEADER_MENU.map(({ id, label, link, externalLink, acceptedLinks, icon }) =>
        externalLink ? (
          <a
            href={externalLink}
            className={`${styles.MenuItem} ${primaryColor === 'dark' ? styles.ActiveDark : ''} ${
              handleIsActive(acceptedLinks)
                ? `${styles.Active} ${primaryColor === 'dark' ? styles.ActiveDark : ''}`
                : ''
            }`}
            target="_blank"
            rel="noreferrer"
          >
            {icon && <ImgLazy className={styles.Icon} src={getCdnImg(icon, 40)} alt={label} />}
            <span className={styles.Label}>{label}</span>
          </a>
        ) : (
          <Link key={id} href={link}>
            <a
              onClick={() => closeMenu()}
              className={`${styles.MenuItem} ${primaryColor === 'dark' ? styles.ActiveDark : ''} ${
                handleIsActive(acceptedLinks)
                  ? `${styles.Active} ${primaryColor === 'dark' ? styles.ActiveDark : ''}`
                  : ''
              }`}
              rel="noreferrer"
              aria-hidden
            >
              {icon && <ImgLazy className={styles.Icon} src={getCdnImg(icon, 40)} alt={label} />}
              <span className={styles.Label}>{label}</span>
            </a>
          </Link>
        )
      )}

      <div className={styles.DiscoverData}>
        <div className={styles.Left}>
          <P3 weight="semibold" className={styles.Title}>
            {`${moment(Date()).format('MMMM')}’s`} Top Platforms
          </P3>
          {investmentsHeader.map(({ name, logo, slug, collapsedDescription }) => (
            <Link href={`/${ROUTES.discover}/${slug}`} key={name}>
              <a className={styles.InvestmentCard} onClick={() => closeMenu()} role="presentation">
                <ImgLazy src={getCdnImg(logo, 24)} alt={name} width={24} height={24} fit="cover" />
                <div className={styles.Content}>
                  <P3 className={styles.Name}>{name}</P3>
                  <P4>{collapsedDescription}</P4>
                </div>
              </a>
            </Link>
          ))}
        </div>
        <div className={styles.Right}>
          <P3 weight="semibold" className={styles.Title}>
            Trending Reads
          </P3>

          {readHeader.map(({ title, slug }) => (
            <Link href={`/${ROUTES.learnArticle}/${slug}`} key={title}>
              <a className={styles.ReadCard} onClick={() => closeMenu()} role="presentation">
                <P3>{title.length > 66 ? `${title.slice(0, 63)}...` : title}</P3>
              </a>
            </Link>
          ))}
        </div>
      </div>

      <div className={styles.AskExpert}>
        <button type="button" className={styles.GoToArt} onClick={handleGoToArt}>
          <P1 weight="semibold">Art investing</P1>
        </button>
      </div>
    </header>
  )
}

HeaderMob.propTypes = {
  primaryColor: PropTypes.string,
  investmentsHeader: PropTypes.arrayOf(PropTypes.object),
  readHeader: PropTypes.arrayOf(PropTypes.object),
  handleSetMobileMenu: PropTypes.func
}

HeaderMob.defaultProps = {
  primaryColor: '',
  investmentsHeader: [],
  readHeader: [],
  handleSetMobileMenu: () => {}
}

export default HeaderMob
