import { HYDRATE } from 'next-redux-wrapper'

import { SET_HUB_FIELD, SET_HUB_FIELDS, SET_HUB_SWIPPED_CARDS, SET_HUB_ALL, RESET_HUB } from './types'

const initialState = {
  onboardingAnswers: [],
  onboardingQuestions: [],
  additionalAnswers: [],
  additionalQuestions: [],
  isQuestionCard: true,
  swipedCards: [],
  cardsForToday: 0,
  updated: 0,
  dailyManualNews: null,
  updatedManualNews: 0,
  dailyNews: null,
  updatedNews: 0,
  dailyPlatform: null,
  updatedPlatform: 0,
  dailyDeal: null,
  updatedDeal: 0
}

const hubReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case HYDRATE: {
      const notEmptyValues = { ...payload.hub }
      // Remove elements with 0 length (works with strings, arrays or objects)
      Object.keys(payload.hub).forEach(
        service =>
          (!notEmptyValues[service] || !Object.keys(notEmptyValues[service]).length) && delete notEmptyValues[service]
      )

      return { ...state, ...notEmptyValues }
    }

    case SET_HUB_FIELD:
      return { ...state, [payload.key]: payload.value }

    case SET_HUB_FIELDS:
      return payload.reduce((acc, { key, value }) => ({ ...acc, [key]: value }), { ...state })

    case SET_HUB_ALL:
      return { ...state, ...payload }

    case SET_HUB_SWIPPED_CARDS: {
      const isAlreadySwipped = state.swipedCards ? state.swipedCards.find(({ key }) => key === payload.key) : false

      if (!isAlreadySwipped) return { ...state, swipedCards: [...state.swipedCards, payload] }
      return state
    }

    case RESET_HUB:
      return {
        ...initialState,
        onboardingQuestions: state.onboardingQuestions,
        additionalQuestions: state.additionalQuestions
      }

    default:
      return state
  }
}

export default hubReducer
